.VehicleForm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.back_button {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #344051;
  color: white;
  padding: 4px 15px;
  gap: 10px;
  border-radius: 5px;
  font-family: "Inter";
  height: 25px;
}
.vehicle_tabs {
  margin-top: 20px;
  background-color: #e9f2ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.mui_vehicle_style {
  text-transform: none !important;
  font-family: "Inter" !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
