.bouncing-loader {
    display: flex;
    justify-content: center;
   
  }
  
  .bouncing-loader > div {
    width: 12px;
    height: 12px;
    margin: 0px 6px;
    border-radius: 50%;
    background-color: #5570F1;
    opacity: 1;
    animation: bouncing-loader 0.6s infinite alternate;
  }
  @keyframes bouncing-loader {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0.1;
      transform: translateY(-6px);
    }
  }
  
  .bouncing-loader > div:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .bouncing-loader > div:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .bouncing-loader > div:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .bouncing-loader > div:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  .bouncing-loader > div:nth-child(6) {
    animation-delay: 0.5s;
  }
  
  .bouncing-loader > div:nth-child(7) {
    animation-delay: 0.6s;
  }
  
  .bouncing-loader > div:nth-child(8) {
    animation-delay: 0.7s;
  }
  
  .bouncing-loader > div:nth-child(9) {
    animation-delay: 0.8s;
  }
  
  .bouncing-loader > div:nth-child(10) {
    animation-delay: 0.9s;
  }
  
  .bouncing-loader > div:nth-child(11) {
    animation-delay: 1s;
  }
  
  .bouncing-loader > div:nth-child(12) {
    animation-delay: 1.1s;
  }
  
  .bouncing-loader > div:nth-child(13) {
    animation-delay: 1.2s;
  }
  
  .bouncing-loader > div:nth-child(14) {
    animation-delay: 1.3s;
  }
  
  .bouncing-loader > div:nth-child(15) {
    animation-delay: 1.4s;
  }
