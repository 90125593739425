.user_filter_input_action_container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.user_filter_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 8px !important;
  font-size: 15px;
  width: 200px;
  border: 1px solid rgba(119, 182, 243, 1) !important ;
}
.user_filter_Icon {
  position: relative;
  display: none;
}
.user_filter_action {
  display: flex;
  gap: 5px;
}
.users_Search_button {
  cursor: pointer;
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 5px 25px !important;
  color: white;
  font-size: 14px;
  line-height: 19.36px;
  font-weight: 600;
}
.users_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 5.5px 25px !important;
  background-color: white;
  color: #005eb8;
  border: 1px solid #005eb8;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 1316px) {
  .user_filter_Icon {
    display: block;
  }
  .user_filter_field_container {
    display: none;
  }
  .user_filter_input_action_container {
    max-width: 200px;
    padding: 8px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
  }
  .user_filter_action {
    display: flex;
    gap: 8px;
    width: 200px;
  }
}
@media only screen and (min-width: 750px) {
  .user_filter_field_container {
    display: block;
  }
}
