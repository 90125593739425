.applicationForm_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.application_form_main_Container{
    margin-top: 20px;
    padding: 25px;
    border-radius: 8px;
    background: #FAFDFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
}
.application_formContainer{
   background-color: white;
   padding: 25px;
   position: relative;
   border: 1px solid #003087;
   border-radius: 10px;
   margin: 30px 0px;
}
.application_form_legend{
    background-color: white;
    position: absolute;
    top: -14px;
    padding: 1px 8px;
    border-radius: 5px;
    color: #005EB8;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.applicationform_field_container{
    margin-bottom: 15px;
}
.applicationform_fields{
    display: grid;
    grid-template-columns: 50fr 50fr;
    column-gap: 20px;
}
.applicationform_fields_labels{
    color: #10002E;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.4px;
    margin-bottom: 5px;
}
.application_remark{
    font-size: 14px;
    color: red;
    font-weight: bold;
    margin-bottom: 5px;
}
.applicationform_action{
    margin-top: 20px;
    display: flex;
    justify-content: end;
    gap: 5px;
}
.terms_and_condition_container{
    display: flex;
    gap: 5px;
}
.terms_and_condition_link{
    color: #0F62FE;
    cursor: pointer;
}
