.checkbox_with_text {
  display: block;
  gap: 10px;
}
.privilage_header {
  font-weight: 500;
  margin: 10px 0px;
  color: rgb(0, 48, 135);
  font-weight: 600;
  font-family: Inter;
}
.privilage_sub_container {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;
  height: 560px;
  overflow: auto;
  margin-top: 10px;
}
.privilage_section_container {
  border: 1px dashed gray;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  padding: 20px 30px;
}
.privilage_form_actions {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  justify-content: end;
}
.Role_tool_field_width .MuiAutocomplete-option {
  min-height: 0px !important;
}
@media only screen and (max-width: 1200px) {
  .privilage_sub_container {
    display: grid !important;
    grid-template-columns: none;
    grid-gap: 10px !important;
  }
}
