.vehicle_task_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.add_vehicle_task {
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 15px;
  background-color: #0f62fe;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.vehicle_name {
  font: "Inter";
  font-weight: 600;
  font-size: 18px;
  padding: 5px 10px;
  background: #efecff;
  color: #673ab7;
  border-radius: 5px;
}
.vechicle_tasks_chipset {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
  min-width: 180px;
}
.vehicleTaskDescription {
  max-width: 30%;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.vehicle_task_view_back_container {
  margin: 20px 0px;
  display: flex;
  justify-content: space-between;
}
.vehicle_task_view__back {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #344051;
  color: white;
  padding: 4px 15px;
  gap: 10px;
  border-radius: 5px;
  font-family: "Inter";
  height: 25px;
}
