.sliderMenus_main_menu_container{
    padding: 15px 30px;  
}
.sliderMenus_main_menu_container:hover{
    background-color: rgb(0 79 155)
}
.subMenu_text:hover{
    background-color: rgb(0 79 155)
}
.sliderMenus_main_menu{
    position: relative; 
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
}
.sliderMenus_icon_text{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 15px;
}
.menu_text{
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: white;
}
.subMenu_text{
    padding: 10px 10px 10px 80px;
    font-family: 'Inter';
    font-size: 16px;
    font-weight: 500;
    color: white;
}
.sliderMenu_dropdown_icon{
    color: white;
}
.sliderMenu_submenu{
    cursor: pointer;
    display: none;
}
.sliderMenus_main_menu_container:hover .sliderMenu_submenu {
    display: block;
}