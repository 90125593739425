.vehile_task_filter_tool_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.vehile_task_filter_tool_field_widt
  .css-1gwz26b-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 2px !important;
  width: 191px !important;
}
.task_custom_date_picker_field {
  width: 200px;
  font-family: "Inter";
  position: relative;
  border: 1px solid rgba(119, 182, 243, 1);
  padding: 7.3px 12px;
  border-radius: 4px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;   
  height:19px;   
  white-space: nowrap;   
  text-overflow: ellipsis; 
}
.custom_date_picker_container {
  width: 170px !important;
  margin-top: 5px;
  position: absolute;
  display: none;
  border: 1px solid #ddd7d7;
  z-index: 9999;
}
.toolbar_Search_button {
  cursor: pointer;
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 7px 25px;
  color: white;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 600;
}
.toolbar_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 7px 25px;
  background-color: white;
  color: #005eb8;
  border: 1px solid #005eb8;
  font-size: 16px;
  font-weight: 500;
}
.customPlaceholder {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  letter-spacing: 0.00938em;
  font-family: 400;
  color: #bbbbbb;
}
.filter_task_container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.vehile_task_filter_tool_field_width
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  font: inherit;
  font-family: "Inter";
  font-size: inherit;
  letter-spacing: inherit;
  color: currentColor;
  padding: 4px 0 5px;
  font-size: 14px;
  border: 0;
  box-sizing: content-box;
  background: none;
  height: 1.4375em;
  margin: 0;
  -webkit-tap-highlight-color: transparent;
  display: block;
  min-width: 170px;
  width: 175px;
  -webkit-animation-name: mui-auto-fill-cancel;
  animation-name: mui-auto-fill-cancel;
  -webkit-animation-duration: 10ms;
  animation-duration: 10ms;
  padding-top: 1px;
  padding: 8.5px 14px;
}
.custom-notched-outline {
  border: none !important;
}
.vehicle_Searchfilter_input {
  padding: 3px 8px !important;
  font-size: 14px;
  font-family: "Inter";
  width: 183px;
  margin-top: -1px;
  height: 29px;
  border-radius: 5px;
  border: 1px solid rgba(119, 182, 243, 1) !important ;
}
.vehile_task_filter_tool_field_width .MuiAutocomplete-option {
  min-height: 0px !important;
}

@media only screen and (max-width: 1316px) {
  .filter_icon_mobile {
    display: flex;
    cursor: pointer;
  }
  #vehicle_filter_task_container {
    display: none;
  }
  .filter_task_container {
    max-width: 200px;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items:flex-start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
  }
  .task_custom_date_picker_field {
    width: 173px ;
    position: relative;
    font-family: "Inter";
    border: 1px solid rgba(119, 182, 243, 1);
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
    height:19px;
  }
  .vehile_task_filter_tool_field_width
    .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    font: inherit;
    font-size: inherit;
    font-family: "Inter";
    letter-spacing: inherit;
    color: currentColor;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 172px;
    width: 100%;
    -webkit-animation-name: mui-auto-fill-cancel;
    animation-name: mui-auto-fill-cancel;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding-top: 1px;
    padding: 8.5px 14px;
  }
  .custom-date-picker_task.rdrDateRangePickerWrapper {
    user-select: none;
    width: 350px;
    height: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: row-reverse;
  }
}
