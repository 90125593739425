.fieldValidation {
  color: red;
  margin-top: 5px;
  font-family: "Inter";
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}
.property_Form {
  width: 500px;
}
.property_form_label {
  font-size: 14px;
  margin: 8px 0px;
  font-weight: 500;
  font-family: "Inter";
  margin-bottom: 5px;
}
.property_type_form_action {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: end;
  gap: 5px;
}
.fileuploader_container{
  border: 2px dashed #9093a1;
  border-radius: 15px;
  margin-top: 10px;

}
.file-upload-containers-bank {
  display: flex;

  height: 60px; /* Adjust based on your layout */
}
.file-upload-containers div {
  width: 100%; /* Adjust size */
  height: 60px; /* Adjust size */
 
  border-radius: 50%; /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor for clickable */
 
}
.attachment_close_icon_Propertytype{
  position: absolute;
  top: -9px;
  left: 163px;
}

.file_upload_text {
  display: flex;
  padding: 10px 20px;
  background-color: #007bff; /* Blue color */
  color: white;
  border: none;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.file_upload_text:hover {
  background-color: #0056b3; /* Darker blue on hover */
}

.property_type_form_input
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 10px 8px !important;
  font-size: 13px !important;
  font-family: "Inter";
}
.property_type_form_input
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px 8px !important;
  font-size: 13px !important;
  font-family: "Inter";
}
.property_type_form_input.css-81mto1-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall .MuiAutocomplete-input {
  padding: 2.5px 4px 2.5px 1px;
  font-size: 13px;
  font-family: "Inter";
}
@media only screen and (max-width: 615px) {
  .property_Form {
    width: 300px;
  }
}
@media only screen and (max-width: 415px) {
  .property_Form {
    width: 200px;
  }
}
