.Property_BankDetails_form_main_Container {
  margin-top: 20px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.Property_BankDetails_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
}
.Property_BankDetails_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.Property_BankDetailsform_field_container {
  margin-bottom: 15px;
}
.Property_BankDetailsform_fields {
  display: flex;
  column-gap: 20px;
}
.Property_BankDetailsform_fields_labels {
  color: #10002e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}
.Property_BankDetailsform_field_container .Mui-disabled {
  background-color: #f8f8f8;
}
.Property_BankDetailsform_action {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.Property_BankDetailsform_dualfields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 5px;
}
.Property_BankDetails_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  font-family: "Inter";
  font-size: 16px;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  padding: 5px;
}
#Property_BankDetailsform {
  max-height: 60vh;
  /* overflow: auto; */
}
.fileuploader_container_Auction_Attachment{
  border: 2px dashed #9093a1;
  border-radius: 15px;
  width: 100%;
}
.file-upload-containers-bank {
  display: flex;

  height: 60px; /* Adjust based on your layout */
}
.Attachment_lists{
  display: flex;
  flex-direction: row;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.file-upload-containers_Attachment div {
  width: 100%; /* Adjust size */
  height: 60px; /* Adjust size */
 flex-direction: column;
  border-radius: 50%; /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor for clickable */
 
}
@media screen and (max-width: 600px) {
  .Property_BankDetailsform_fields {
    grid-template-columns: 1fr;
  }
  .Property_BankDetailsform_dualfields {
    display: flex;
    flex-direction: column;
    column-gap: 5px;
  }
}
