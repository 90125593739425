.toggle_main_container{
    cursor: pointer;
    width: 60px;
    margin: 10px 20px;
    /* position: absolute;
    top: 0;
    right: 0; */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgb(252, 252, 252);
}
.toggle_main_container:hover{
    border: 1px solid #77B6F3;
}
.languagecode{
    padding: 5px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 400;
}
.flagImage{
    width: 20px;
    height: 20px;
}