.update_vehicle_task_assigne_Form{
    width: 500px;
}
.update_vehicle_task_assigne_Form_label{
    font-size: 15px;
    margin: 8px 0px;
    font-family: "Inter";
    font-weight: 500;
}

@media only screen and (max-width:615px) {
    .update_vehicle_task_assigne_Form{
        width: 300px;
    }
}
@media only screen and (max-width:415px) {
    .update_vehicle_task_assigne_Form{
        width: 200px;
    }
}
.update_vehicle_task_assigne_Form_actions{
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: flex-end;
}