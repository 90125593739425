.notification_dropdown_main_container{
    border-radius: 10px;
    padding: 10px;
    margin: 5px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
}
.notification_dropdown_email_dateTime{
    display: flex;
    align-items: center;
    gap: 5px;
}
.notification_dropdown_email{
    font-size: 12px;
    font-weight: bold;
}
.notification_dropdown_dateTime{
    font-size: 10px;
    color: gray;
}
.notification_dropdown_message{
    margin-top: 10px;
    font-size: 14px;
}
.no_notification_image_dropdown{
    width: 50%;
    height: 50%;

}