.CustomInterestApplicationFilter_input_action_container{
    display: flex;
    align-items: center;
    gap: 10px;
    max-width: 1250px;
}
.CustomInterestApplicationFilter_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 6px 8px !important;
    font-size: 15px;
    border: 1px solid rgba(119, 182, 243, 1) !important ;
}
.CustomInterestApplicationFilter_action{
    display: flex;
    gap: 10px;
}