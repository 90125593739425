.message_user_profile_main_container{
    padding: 5px 0px;
    border-bottom: 1px solid rgb(216, 216, 216);
    border-radius: 8px;
}
.message_user_sub_container{
    display: flex;
    align-items: center;
    gap: 15px;
}
.message_user_profile_pic{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.message_user_name{
    font-family: "Inter";
    font-size: 16px;
    font-weight: 600;
    color:black;
}
.message_user_email{
    margin-top: 5px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    color:rgb(168, 168, 168);
}