.buttons {
    display: flex;
    margin-top: 49px;
    background-color: #f1f1f1;
    color: #000000;
    height: 81px;
    width: 31px;
    margin-left: "12px";
    border-radius: 3;
  }
  .backbuttons {
    display: flex;
    margin-top: 52px;
    background-color: #f1f1f1;
    color: #000000;
    height: 80px;
    width: 31px;
  }
  .image_flex {
    width: 100%;
    height: 330px;
    display: flex;
    margin-top: 25px;
  }
  .image_mobile_flex {
    display: none;
  }
  .image_flex_ipad {
    display: none;
  }
  .image_flex_ipad {
    display: none;
  }
  .container_image {
    display: none;
  }
  .container_web {
    display: block;
  }
  .propertylider_backbuttonsss{
    display: flex;
    background-color: #f1f1f1;
    color: #000000;
    height: 80px;
    width: 25px;

  }
  .Property_backbuttons{
    width: 0; 
    height: 0; 
    border-top: 44px solid transparent;
  border-bottom: 49px solid transparent;
  border-right: 30px solid rgba(255, 255, 255, 0.6); 
  }
  .Propright_buttons{
    width: 0; 
    height: 0; 
    border-top: 46px solid transparent;
    border-bottom: 48px solid transparent;
    border-left: 29px solid rgba(255, 255, 255, 0.6);
  }
  .Prop_backbuttons {
    display: flex;
    height: 50px;
    width: 50px;
    margin-top: 52px;
    background-color: rgba(255, 255, 255, 0.8); /* Matching background color */
    color: #000;
    font-size: 24px; /* Increase icon size */
    transition: transform 0.2s ease, background-color 0.3s ease;
  }
  
  .vehicleslider_backbuttons:hover {
    background-color: rgba(255, 255, 255, 1); /* Full white on hover */
    transform: scale(1.1); /* Slightly enlarge on hover */
  }
  
  @media only screen and (max-width: 600px) {
    .image_mobile_flex {
      display: block;
    }
    /* .image_flex {
      display: none;
    } */
    .image_flex_ipad {
      display: none;
    }
  
    .container_image {
      display: block;
    }
    .container_web {
      display: none;
    }
    .buttons {
      display: flex;
    margin-top: 24px;
    background-color: #f1f1f1;
    color: #000000;
    height: 81px;
    width: 25px;
    }
    .backbuttons {
      display: flex;
    margin-top: 25px;
    background-color: #f1f1f1;
    color: #000000;
    height: 83px;
    width: 25px;
    }
    .image_flex_ipad {
      display: none;
    }
    .propertylider_backbuttonsss{
      display: flex;
      background-color: #f1f1f1;
      color: #000000;
      height: 80px;
      width: 25px;
  
    }
  }
  @media only screen and (max-width: 850px) {
    .image_flex_ipad {
      display: block;
    }
    .image_mobile_flex {
      display: none;
    }
    /* .image_flex {
      display: none;
    } */
  }
  