.CutomV1_attachment_main_container {
    margin-top: 25px;
    border: 1px dashed rgb(202, 202, 202);
    background-color: white;
    padding: 25px;
    border-radius: 10px;
  }
  .CutomV1_attachment_title {
    margin-bottom: 20px;
  }
  
  .CutomV1_input_file_container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5px;
  }
  #CutomV1_task-input-file {
    flex-grow: 2;
  }
  #CutomV1_task-input-title {
    flex-grow: 2;
  }
  #CutomV1_task-input-upload-btn {
    flex-grow: 1;
  }
  
  .CutomV1_file_upload_icon {
    color: blue;
  }
  .CutomV1_choose_file_field {
    font-size: 14px;
    color: gray;
    display: flex;
    align-items: center;
    gap: 5px;
    padding: 7px 8px;
    border: 1px solid darkgrey;
    border-radius: 5px;
  }
  .CutomV1_attachment_main_container {
    margin-top: 30px;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    position: relative;
  }
  .CutomV1_attachment_container {
    position: relative;
  }
  .CutomV1_attachment_close_icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }
  .CutomV1_attachemnt_cancelIcon {
    cursor: pointer;
    border-radius: 20px;
    background-color: white;
    color: red;
  }
  .CutomV1_image_attachment {
    border-radius: 10px;
    width: 150px;
    height: 80px;
    background-size: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 1px;
  }
  .CutomV1_attachment_sub_container {
    display: flex;
    gap: 10px;
    align-items: center;
  }
  .CutomV1_file_attachment {
    width: 40px;
    height: 50px;
  }
  .CutomV1_file_attachment_container {
    border-radius: 5px;
    position: relative;
    background: #f5f7ff;
    padding: 9px 15px;
    margin: 10px;
  }
  .CutomV1_file_title {
    color: gray;
    font-size: 13px;
  }
  .CutomV1_file_size {
    font-size: 10px;
    color: gray;
  }
  .CutomV1_edit_details {
    display: flex;
    gap: 5;
    color: #003087;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
  }
  
  .CutomV1_Main-Div {
    margin-top: 5px;
  }
  
  .CutomV1_input_file_container {
    display: flex;
    flex-wrap: nowrap;
  }
  
  #CutomV1_task-input-file {
  width: 300px;
  flex-grow: 0;
  }
  #CutomV1_task-input-title {
  width: 300px;
  flex-grow: 0;
  }
  #CutomV1_task-input-upload-btn {
  width: 200px;
  flex-grow: 0;
  }
  
  .CutomV1_attachment_main_container_main {
    display: flex;
    flex-wrap: wrap;
    padding: 10px;
  }
  .CutomV1_attachment_main_container1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
  }
  .CutomV1_attachment_main_container2{
    display: flex;
    flex-wrap: wrap;
    justify-content: left;
    width: 100%;
  }
  
  .CutomV1_img-div-attachment {
    margin: 10px;
    position: relative;
  }
  .CutomV1_field_error{
    position: absolute;
    color: red;
    font-family: 'Inter';
    font-weight: 500;
    font-size: 14px;
  }
  @media only screen and (max-width: 650px) {
    .CutomV1-Main-Div {
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .CutomV1_input_file_container {
      width: 100%;
      flex-wrap: wrap;
    }
    #CutomV1_task-input-file {
      width: 100%;
    }
    #CutomV1_task-input-title {
      width: 100%;
    }
    #CutomV1_task-input-upload-btn {
      width: 100%;
    }
    .CutomV1_attachment_main_container_main {
      width: 100%;
    }
  }
