.role_filter_Icon {
  display: none;
}
.role_filter_input_container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.role_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6px 8px !important;
  font-size: 15px;
  border: 1px solid rgba(119, 182, 243, 1) !important ;
}
.role_filter_action_button {
  display: flex;
  gap: 8px;
}
.roles_Search_button {
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 5px 25px !important;
  color: white;
  font-size: 14px;
  line-height: 19.36px;
  font-weight: 600;
}
.roles_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 5.5px 25px !important;
  background-color: white;
  color: #005eb8;
  border: 1px solid #005eb8;
  font-size: 14px;
  font-weight: 500;
}

@media only screen and (max-width: 650px) {
  .role_filter_Icon {
    display: flex;
  }
  .role_filter_field_container {
    display: none;
  }
  .role_filter_input_container {
    max-width: 192px;
    padding: 8px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
  }
  .role_filter_action_button {
    display: flex;
    margin: 0px 0px 0px auto;
    gap: 8px;
  }
}
@media only screen and (min-width: 650px) {
  .role_filter_field_container {
    display: block;
  }
}
