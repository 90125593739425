.filter_tool_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.filter_tool_field_widt
  .css-1gwz26b-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 2px !important;
}
.custom_property_date_picker_field {
  width: 200px;
  font-family: "Inter";
  position: relative;
  border: 0.5px solid rgba(119, 182, 243, 1);
  padding: 7.5px 12px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
  overflow: hidden;    
  white-space: nowrap;  
  height:19px;
  text-overflow: ellipsis;
}
.custom_date_picker_container {
  margin-top: 5px;
  position: absolute;
  display: none;
  z-index: 9999;
}
.toolbar_Search_button {
  cursor: pointer;
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 7px 25px;
  color: white;
  font-size: 14px;
}
.toolbar_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 7px 25px;
  background-color: white;
  color: #005eb8;
  font-size: 14px;
  border: 1px solid #005eb8;
}
.customPlaceholder {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  letter-spacing: 0.00938em;
  font-family: 400;
  color: #bbbbbb;
  font-size: 15px;
}
.filter_tool_field_width .MuiAutocomplete-option {
  min-height: 0px !important;
}

@media only screen and (max-width: 1316px) {
  .filter_icon_mobile {
    display: flex;
    cursor: pointer;
  }
  #property_filter_field_container {
    display: none;
  }
  .filter_field_container {
    max-width: 200px;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 999;
  }
  .custom_property_date_picker_field {
    width: 172px !important;
    position: relative;
    border: 0.5px solid rgba(119, 182, 243, 1);
    padding: 7.5px 12px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .custom-date-picker.rdrDateRangePickerWrapper {
    user-select: none;
    width: 365px;
    overflow: scroll;
    display: flex;
    flex-direction: row-reverse;
    border: 0.5px solid rgba(119, 182, 243, 1);
    height: 380px;
    background-color: white;
  }
}
