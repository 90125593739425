.fieldValidation{
    color: red;
    margin-top: 5px;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal
}
.vehicle_Form{
    width: 500px;
}
.vehicle_form_label{
    font-size: 15px;
    margin: 8px 0px;
    font-weight: 500;
    font-family: "Inter";
}
.vehicle_type_form_action{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: "Inter";
    gap: 5px;
}
.file_upload_text{
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: "inter";
    
}
.file-upload-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px; /* Adjust based on your layout */
  }
  
  .file-upload-container div {
    width: 60px; /* Adjust size */
    height: 60px; /* Adjust size */
    background-color: #e0e0e0; /* Customize color */
    border-radius: 50%; /* Make it round */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Pointer cursor for clickable */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for effect */
  }
  
  .file-upload-container div:hover {
    background-color: #d0d0d0; /* Change color on hover */
  }
  
  .file-upload-container svg {
    color: #555; /* Customize icon color */
    font-size: 24px; /* Adjust icon size */
  }
  
.vehicle_type_form_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 7px 8px !important;
    font-size: 15px !important;
    font-family: "Inter" !important;
}
.vehicle_type_form_input .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 7px 8px !important;
    font-size: 15px !important;
}
.img-div-attachment {
    margin: 10px;
    position: relative;
  }
  .attachment_close_icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }
  .attachemnt_cancelIcon {
    cursor: pointer;
    border-radius: 20px;
    background-color: white;
    color: red;
  }
  .image_attachment {
    border-radius: 10px;
    width: 150px;
    height: 80px;
    background-size: cover;
  }
.file_upload_button{
    background-color: #ff5f5f; /* Red background color */
    color: white; /* White text color */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Padding for size */
    font-size: 14px; /* Text size */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
@media only screen and (max-width:615px) {
    .vehicle_Form{
        width: 300px;
    }
}
@media only screen and (max-width:415px) {
    .vehicle_Form{
        width: 200px;
    }
}