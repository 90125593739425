.vehicle_attachment_main_container {
  margin-top: 25px;
  border: 1px dashed rgb(202, 202, 202);
  background-color: white;
  padding: 25px;
  border-radius: 10px;
}
.attachment_title {
  margin-bottom: 20px;
}
.input_file_container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 10px;
  font-family: "Inter";
}
#task-input-file {
  flex-grow: 2;
}
#task-input-title {
  flex-grow: 2;
}
#task-input-upload-btn {
  flex-grow: 1;
}

.file_upload_icon {
  color: blue;
}
.choose_vehicle_file_field {
  font-size: 14px;
  color: gray;
  min-width: 100px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 9.5px 8px;
  border: 1px solid darkgrey;
  border-radius: 5px;
}
.attachment_main_container {
  margin-top: 30px;
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  position: relative;
}
.attachment_container {
  position: relative;
}
.vedioattachment_container{
  position: relative;
  margin-top: 10px;
}
.attachment_close_icon_type {
  position: absolute;
  top: -5px;
  left: 145px;
}
.attachemnt_cancelIcon {
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  color: red;
}
.image_attachment {
  border-radius: 10px;
  width: 150px;
  height: 80px;
  background-size: cover;
}

.attachment_sub_container {
  display: flex;
  gap: 10px;
  align-items: center;
}
.file_attachment {
  width: 40px;
  height: 50px;
}
.file_attachment_container {
  border-radius: 5px;
  position: relative;
  background: #f5f7ff;
  padding: 9px 15px;
  margin: 10px;
}
.file_title {
  color: gray;
  font-size: 13px;
}
.file_size {
  font-size: 10px;
  color: gray;
}
.VehicleAppraisal_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.VehicleAppraisal-Main-Div {
  padding: 20px;
}

.input_file_container {
  display: flex;
  flex-wrap: nowrap;
}
#task-input-file {
  width: 300px;
  flex-grow: 0;
}
#task-input-title {
  width: 300px;
  flex-grow: 0;
}
#task-input-upload-btn {
  width: 200px;
  flex-grow: 0;
  font-size: 14px;
  font-family: "Inter";
}
.error-visible {
  margin-bottom: 20px;
}
.attachment_main_container_main {
  display: flex;
  flex-wrap: wrap;
  padding: 10px;
}
.attachment_main_container1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}
.attachment_main_container2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  width: 100%;
}
.img-div-attachment {
  margin: 10px;
  position: relative;
}
.buttonContainer {
  display: flex;
}

@media only screen and (max-width: 650px) {
  .VehicleAppraisal-Main-Div {
    flex-wrap: wrap;
    justify-content: center;
  }
  .input_file_container {
    width: 100%;
    flex-wrap: wrap;
  }
  #task-input-file {
    width: 100%;
  }
  #task-input-title {
    width: 100%;
  }
  #task-input-upload-btn {
    width: 100%;
  }
  .attachment_main_container_main {
    width: 100%;
  }
  .buttonContainer {
    justify-content: center;
  }
  .image_attachment {
    border-radius: 10px;
    width: 100px;
    height: 80px;
    background-size: cover;
    margin-left: -17px;
  }
}
