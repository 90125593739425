.advance_table_toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 25px 0px;
}
.advance_table_mainContainer {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
  overflow-y: auto;
  /* max-height: 400px; */
  max-height: 63vh;
}
.advance_table_toolbar_setting {
  position: relative;
}
.advance_table_toolbar_setting_dropdown {
  display: none;
  border: 1px solid rgba(156, 156, 156, 0.567);
  position: absolute;
  background-color: rgb(255, 255, 255);
  right: 10px;
  width: 300px;
  border-radius: 5px;
  padding: 10px;
  z-index: 10;
}
.setting_dropdown_toolbar {
  display: flex;
  justify-content: space-between;
}
.setting_dropdown_items {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 10px 0px;
}
.advance_table_column_list {
  max-height: 50vh;
  overflow: auto;
}
.table_pagination_container {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}
.table_pagination_button {
  cursor: pointer;
  border-radius: 5px;
  border: none;
  padding: 5px 8px;
  background-color: white;
  filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16));
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.advance_table_pagination
  .css-r93niq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
  color: #fff;
  background-color: #1976d2;
}
.noData_Container {
  position: relative;
  width: 100%;
  height: 200px;
  text-align: center;
}
.noData_text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.setting_list_view {
  font-family: "inter";
  color: #9093a1;
  font-weight: 600;
  font-size: 15px;
  line-height: 27.91px;
  cursor: pointer;
}
.checkList_view {
  font-family: "inter";
  color: #003087;
  font-weight: 400;
  font-size: 15px;
  line-height: 27.91px;
  cursor: pointer;
}
.list_item {
  font-family: "inter";
  font-weight: 400;
  font-size: 15px;
  line-height: 27.91px;
}
.table_body_container {
  max-height: 300px;
  overflow-y: auto;
}
.sticky-actions {
  position: sticky;
  right: 0;
  box-shadow: inset 1px 0px 0px rgb(235 235 235); /* CSS3 */
  margin: -1px;
  padding: 12px;
  width: 100px !important;
  justify-content: end;
}
.sticky-actions1 {
  position: sticky;
  right: 0;
  box-shadow: inset 1px 0px 0px rgb(235 235 235); /* CSS3 */
  margin: -1px;
  padding: 12px;
  width: 100px !important;
}
