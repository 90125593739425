.page_title{
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}
.user_filter_container{
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.add_users_button{
    margin-left: 5px;
    white-space: nowrap;
    text-transform: none;
}
.user_action_chipset{
    display: flex;
    gap:5px;
    justify-content: center;
}