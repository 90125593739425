.filter_tool_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
/* .filter_tool_field_widt
  .css-1gwz26b-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 2px !important;
} */
.custom_vehicle_date_picker_field {
  width: 200px;
  font-family: "Inter";
  height:19px;
  position: relative;
  border: 0.5px solid rgba(119, 182, 243, 1);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: hidden;      /* Ensure content is clipped */
  white-space: nowrap;   /* Prevent text from wrapping */
  text-overflow: ellipsis; /* Add ellipsis when text overflows */
}
.custom_date_picker_container {
  width: 170px !important;
  margin-top: 5px;
  position: absolute;
  display: none;
  border: 0.5px solid rgba(119, 182, 243, 1);
  z-index: 9999;
}
/* .custom_vehicle_date_picker_field {
  width: 192px ;
  position: relative;
  border: 0.5px solid rgba(119, 182, 243, 1);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 14px;
  display: flex;
  justify-content: space-between;
} */
.toolbar_Search_button {
  cursor: pointer;
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 7px 25px;
  color: white;
  font-size: 16px;
  line-height: 19.36px;
  font-weight: 600;
}
.toolbar_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 7px 25px;
  background-color: white;
  color: #005eb8;
  border: 1px solid #005eb8;
  font-size: 16px;
  font-weight: 500;
}
.customPlaceholder {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  letter-spacing: 0.00938em;
  font-family: 400;
  color: #bbbbbb;
}
.filter_field_container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.filter_field_container.show {
  display: block;
}
.filter_icon_mobile {
  display: none;
}
.toolbar_Search {
  display: flex;
  gap: 8px;
}
.rdrDateRangePickerWrapper {
  display: inline-flex;

  user-select: none;
  border: 0.2px solid #b0b0b0;
}
.custom-notched-outline {
  border: none !important;
}
@media only screen and (max-width: 1316px) {
  .filter_icon_mobile {
    display: flex;
    cursor: pointer;
  }
  #vehicle_filter_field_container {
    display: none;
  }
  .filter_field_container {
    max-width: 200px;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: flex-start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
  }
  .custom_vehicle_date_picker_field {
    width: 172px ;
    position: relative;
    border: 0.5px solid rgba(119, 182, 243, 1);
    padding: 9.5px 12px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  .custom-date-picker.rdrDateRangePickerWrapper {
    user-select: none;
    width: 365px;
    overflow: scroll;
    display: flex;
    flex-direction: row;
    border: 0.5px solid rgba(119, 182, 243, 1);
  }
}
