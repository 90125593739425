.Properties_page_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.page_title{
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}
.add_Properties_button{
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 15px;
    background-color: #0F62FE;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.table_pagination_container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
.property_chipset{
    display: flex;
    align-items: flex-end;
    /* flex-wrap: wrap; */
    gap: 10px;
    justify-content: end;
    min-width: 170px;
}
.property_chipset_agent{
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    min-width: 170px;
}