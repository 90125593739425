.Vehicle_details_Titile {
  display: flex;
  flex-direction: row;
  gap: 10px;
}
.titile_details {
  font-size: 22px;
  max-width: 27ch; 
  white-space: pre-wrap; 
  word-break: break-word;
}
.circle_details {
  height: 30px;
  /* position: absolte; */
  width: 30px;
  background-color: rgb(226, 206, 206);
  border-radius: 50%;
  display: inline-block;
  /* right: 0; */
  opacity: 0.5;
}
.custom-typography {
  display: flex;
  align-items: center;
  margin-top: 8px;
}

.price {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
}

.text {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}
.characteristics_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  max-width: 300px;
  margin-top: 7px;
  background-color: #f6faff;
  padding: 10px;
}

.Property_card_titile {
  font-size: 18px;
  font-weight: 600;
}
.vwhicle_details {
  display: flex;
  flex-direction: row;
  margin-top: 12px;
  /* justify-content: space-between; */
  /* gap:120px; */
}
.vehicle_data {
  display: flex;
  justify-content: flex-start;
  width: 200px;
}
.vehicle_value {
  display: flex;
  width: 200px;
}
.Featuredvehi_containerview {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 256px;
  min-height: 370px;
  border-radius: 8px;
  gap: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.vehicle_listing__img {
  display: flex;
  /* margin-top: -12px; */
  width: 100%;
  /* border-top-left-radius: 8px;
              border-top-right-radius: 8px; */
  /* border-radius: 8px; */
}
.FeaturedProp_image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}
/* .vehicle_listing_titile {
            display: flex;
            font-size: 17px;
            color: black;
            font-weight: 600;
          } */
.vehicle_listing_location {
  display: flex;
  font-size: 13px;
  color: #9093a1;
}
.vehicle_listing_date {
  display: flex;
  font-size: 14px;
}
.details_container_vehicle_listing {
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 20px;
}
.vehicle_about {
  font-size: 16px;
  margin-top: 10px;
}
.vehicle_similar_rectangle {
  width: 44px;
  height: 20px;
  background-color: #005eb8;
  position: absolute;
  margin-left: -28px;
  margin-top: -40px;
  color: #ffffff;
  font-size: 12px;
  padding: 2px 21px;
  border-radius: 4px;
}
.vehicle_similar_rectangle.trending {
  background-color: #FC1551;
}

/* Blue background for popular */
.vehicle_similar_rectangle.popular {
  background-color: #005eb8;
}
.vehicle_similar_triangle-down {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -28px;
  margin-top: -19px;
  border-left: 9px solid transparent;
  border-right: 0px solid transparent;
  border-top: 9px solid #005eb8;
}
/* Red border for trending */
.vehicle_similar_triangle-down.trending {
  border-top-color: #FC1551; /* Change border color for Trending */
}

/* Blue border for popular */
.vehicle_similar_triangle-down.popular {
  border-top-color: #005eb8; /* Change border color for Popular */
}
.vehicle_listing_ {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -28px;
  margin-top: -19px;
  border-left: 9px solid transparent;
  border-right: 0px solid transparent;
  border-top: 9px solid #005eb8;
}
.conatiner_titile_FeaturedVehicleview {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 12px;

 
}
.circle {
  height: 30px;
  position: absolute;
  z-index: 9;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  right: 0;
  opacity: 0.5;
}
.characteristics_icon {
  font-size: 32px;
}
.aboutbank_content {
  font-size: 16px;
}
.disclaimer_content {
  font-size: 16px;
}
.Featuredvehi_titile_view {
  display: flex;
  font-size: 17px;
  color: black;
  font-weight: 600;
  max-width: 27ch; 
  white-space: pre-wrap; 
  word-break: break-word;
}
.FeaturedVehicleview_sub_container{
  display: flex;
  gap: 28px;
  flex-wrap: wrap;
}
.Vehicleview_fullcontainer{
  padding: 24px 30px;
}
.Autobot_Maincontainer {
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 0;
}
.image_bot{
width:85px;

}
.custom_modal_vehicle{
  display: flex;

}
@media only screen and (max-width: 600px) {
  .Vehicleview_fullcontainer{
    padding: 20px;
  }
  .characteristics_container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    max-width: 300px;
    margin-top: 7px;
  }
  .vehicle_data {
    display: flex;
    justify-content: flex-start;
    width: 130px;
    font-size: 14px;
  }
  .vehicle_value {
    display: flex; /* Use flexbox layout */
    justify-content: flex-start; /* Align items to the start of the flex container */
    align-items: flex-start; /* Align items to the top of the flex container */
    font-size: 14px; /* Set the font size to 14 pixels */
    max-width: 14ch; /* Set the maximum width to 12 characters wide */
    word-break: break-word;
  }
  .aboutbank_content {
    font-size: 14px;
  }
  .vehicle_about {
    font-size: 14px;
    margin-top: 10px;
  }
  .disclaimer_content{
    font-size: 14px;
  }
  .titile_details {
    font-size: 22px;
    
    max-width: 27ch; 
    white-space: pre-wrap; 
    word-break: break-word;
  }
  .conatiner_titile_FeaturedVehicleview {
    display: flex;
    font-size: 20px;
    font-weight: 600;
    /* position: absolute; */
    margin-top: 0px;
    margin-left: 0px;
  }
  .FeaturedVehicleview_sub_container{
    display: flex;
    gap: 28px;
    flex-wrap: wrap;
    justify-content: center;
  }
}
.agent_view_header {
  display: flex;
  justify-content:space-between;
  padding: 0px 20px;
}