.ConservationStatus_form_main_Container {
  margin-top: 20px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.ConservationStatus_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
}
.ConservationStatus_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#ConservationStatusform {
  max-height: 56vh;
  overflow: auto;
}
.ConservationStatusform_field_container {
  margin-bottom: 20px;
}
#ConservationStatusform_field_container {
  position: relative;
  top: 2px;
}
.ConservationStatusform_fields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 40px;
  padding: 15px;
}
.ConservationStatusform_fields_labels {
  color: #10002e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}
.ConservationStatusform_action {
  margin-top: 20px;
  display: flex;
  font-size: 16px;
  justify-content: flex-end;
  gap: 10px;
}
.ConservationStatusform_dualfields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 5px;
}
.ConservationStatus_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  font-family: "Inter";
  font-size: 16px;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  padding: 5px;
}
.ConservationStatusform_field_container .Mui-disabled {
  background-color: #f8f8f8;
}
@media screen and (max-width: 600px) {
  .ConservationStatusform_fields {
    grid-template-columns: 1fr;
  }
}
