.user_list_semi_container{
    padding: 5px;
    margin: 5px;
}
.message_users_list_super_container{
    border-right:1px solid rgb(236, 236, 236)
}

.message_users_list_main_container{
    overflow: auto;
    height: 78vh
}
.message_profile_pic{
    width: 50px;
    height: 50px;
    border-radius: 50px;
}
.message_user_main_container{
    display: flex;
    padding: 5px;
    margin: 5px;
    align-items: center;
    justify-content: space-between;
    border-radius: 5px;
    cursor: pointer;
}
.message_user_super_container :hover{
    background-color:#f1f1f1;
}
.message_user_main_container_1{
    display: flex;
    align-items: center;
    gap: 10px;
}
.user_list_user_name{
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
}
.message_user_super_container .message_user_main_container_1 > div:first-child {
    pointer-events: none; /* Disable pointer events on the first child div */
  }
.user_list_user_message{
    font-family: "Inter";
    font-size: 13px;
    font-weight: 400;
    margin-top: 5px;
    color: rgb(177, 175, 175);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
}
.unread_bardge{
    background-color: #77a6ed;
    padding: 5px;
    border-radius: 50px;
}
.users_list_search{
   outline: none;
   border: 1px solid rgb(207, 207, 207);
   padding: 10px;
   margin: 10px;
   width: 270px;
   border-radius: 5px;
}
.message_users_list_main_container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.message_users_list_main_container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}