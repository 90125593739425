.SubContainer_details_container1 {
  margin-top: 20px;
  padding: 30px 50px;
  display: flex;
  gap: 40px;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
}
.SubContainer_details_container2 {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.SubContainer_details_container2_1 {
  position: relative;
}
.SubContainer_details_container2_2 {
  position: absolute;
  bottom: 20px;
  right: 0;
  background-color: white;
  padding: 3px;
  border-radius: 50%;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.SubContainer_details_container_Image {
  width: 150px;
  height: 150px;
  object-fit: cover;
  border-radius: 50%;
}
.SubContainer_details_container2_mobile{
    display:none;
}
.userProfile_user_name {
  text-align: center;
  font-family: "inter";
}
.userProfile_status_tag {
  text-align: center;
  font-family: "inter";
  padding: 5px;
  background-color: rgb(80, 148, 80);
  color: white;
  font-weight: 700;
  border-radius: 10px;
  font-size: 12px;
}
.userProfile_details {
  display: grid;
  grid-template-columns: 200px auto;
  padding: 5px 0px;
}
.userProfile_Change_Password_tag {
  text-align: center;
  font-family: "inter";
  padding: 5px;
  font-weight: 700;
  color: #2f4d9b;
  border-radius: 10px;
  font-size: 12px;
  border: 1px solid #2f4d9b;
  cursor: pointer;
}
.userProfile_details_placeholder {
  font-size: 15px;
  font-family: "inter";
  font-weight: 600;
}
.userProfile_details_text {
  font-size: 15px;
  font-family: "inter";
  font-weight: 400;
}
.SubContainer_details_container_Image_mobile{
    display: none;
  }
@media only screen and (max-width: 600px) {
  .SubContainer_details_container1 {
    margin-top: 10px;
    padding: 20px 10px;
    display: flex;
    gap: 15px;
    border-radius: 10px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 2px 8px;
  }
  .SubContainer_details_container2 {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .SubContainer_details_container_Image {
   display: none;
  }
  .SubContainer_details_container2_1 {
    position: relative;
  }
  .SubContainer_details_container2_2 {
    display: none;
  }
 .SubContainer_details_container2_mobile{
    display: block;
    position: absolute;
    bottom: 0px;
    right: 13px;
    background-color: white;
    padding: 1px;
    border-radius: 50%;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  }
  .SubContainer_details_container_Image {
    width: 75px;
    height: 72px;
    object-fit: cover;
    border-radius: 50%;
    margin-left: 8px;
  }
  .SubContainer_details_container_Image_mobile{
    display: block;
  }
  .userProfile_user_name {
    text-align: center;
    font-size: 13px;
    font-family: "inter";
    font-weight: 600;
  }
  .userProfile_status_tag {
    text-align: center;
    font-family: "inter";
    padding: 5px;
    color: white;
    font-weight: 700;
    border-radius: 10px;
    font-size: 10px;
  }
  .userProfile_details {
    display: grid;
    grid-template-columns: 115px auto;
    padding: 5px 0px;
  }
  .userProfile_Change_Password_tag {
    text-align: center;
    font-family: "inter";
    padding: 5px;
    font-weight: 700;
    color: #2f4d9b;
    border-radius: 10px;
    font-size: 10px;
    border: 1px solid #2f4d9b;
    cursor: pointer;
  }
  .userProfile_details_placeholder {
    font-size: 13px;
    font-family: "inter";
    font-weight: 600;
  }
  .userProfile_details_text {
    font-size: 12px;
    font-family: "inter";
    font-weight: 500;
    max-width: 13ch; 
  word-wrap: break-word;
  }
}
