.TasksOrJobsPage_page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}
.TasksOrJobsPage_page_sub_header {
  display: flex;
  gap: 15px;
  align-items: center;
}
.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.add_go_to_main_button {
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 15px;
  background-color: #0f62fe;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.custom_toggle_button_style {
  padding: 0px 30px;
  font-family: "Inter";
  font-size: 15px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0.4px;
  text-align: center;
}
.go_to_main_button_container {
  width: 92px;
  border-radius: 5px;
  background-color: white;
  position: absolute;
  flex-direction: column;
  align-items: start;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  z-index: 1;
}
.go_to_main_button {
  display: flex;
  padding: 8px 0;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.buton_sample {
  text-align: center;
}
.go_to_main_button:hover {
  background-color: aliceblue;
}
.Select_task_xs {
  display: none;
}

@media only screen and (max-width: 600px) {
  .TasksOrJobsPage_page_sub_header {
    display: flex;
    gap: 20px !important;
    align-items: start !important;
    flex-direction: column;
  }
  .page_title {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: auto;
  }
  .Select_task {
    display: none;
  }
  .Select_task_xs {
    display: block;
    margin-left: auto;
  }
  .Titile_task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 390px;
  }
  .Button_add_tsk {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(15, 98, 254);
    color: white;
    padding: 4px 8px;
    gap: 3px;
    border-radius: 5px;
    font-family: "Inter";
    height: 25px;
  }
}

@media only screen and (max-width: 450px) {
  .TasksOrJobsPage_page_sub_header {
    display: flex;
    gap: 20px !important;
    align-items: start !important;
    flex-direction: column;
  }
  .page_title {
    font-family: "Inter";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-right: auto;
  }
  .Select_task {
    display: none;
  }
  .Select_task_xs {
    display: block;
    margin-left: auto;
  }
  .Titile_task {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 330px;
  }
}
