.custom_dashboard_small_item_container{
        display: flex;
        align-items: center;
        gap:20px;
        box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
        margin: 0px 5px;
        padding: 10px;
        border-radius: 10px;
}
.custom_dashboard_small_item_count{
    font-family: "Inter";
    font-size: 20px;
    font-weight: 600;
}
.custom_dashboard_small_item_name{
    margin-top: 6px;
    font-family: "Inter";
    font-size: 15px;
    font-weight: 500;
}