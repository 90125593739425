.vehicle_page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.add_vehicle_button {
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 15px;
  background-color: #0f62fe;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.tagLayout{
  font-family: "Inter";
  font-size: 13px;
  font-weight: 600;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  padding: 4px 0px;
}
.vechicle_chipset {
  display: flex;
  align-items:end;
  gap: 10px;
  justify-content: flex-end;
  min-width: 170px;
}
.vechicle_chipset_agent {
  display: flex;
    align-items: center;
    gap: 10px;
    justify-content: center;
    min-width: 170px;
}