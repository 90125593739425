.forgot_password_main_container{
    height: 60vh;
    position: relative;
}
.forgot_password_sub_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;
    padding: 30px;
    border-radius: 20px;
}
.forgot_password_info_icon_container{
    display: flex;
    justify-content: center;
    margin: 20px 0px;
}
.forgot_password_text{
    text-align: center;
    font-family: 'Inter';
    color: black;
    font-size: 18px;
    font-weight: 700;
    margin: 10px 0px;
}
.forgot_password_info_text{
    text-align: center;
    font-family: 'Inter';
    color: rgb(170, 170, 170);
    font-size: 15px;
    font-weight: 400;
    margin: 5px 0px;
}
.forgot_password_form{
    margin: 30px 0px;
   
}
.forgot_password_submit_button{
    margin-top: 40px;
    cursor: pointer;
    border: none;
    padding: 10px 0px;
    border-radius: 9.224px;
    background: #002f87d0;
    color:  #FFF;
    text-align: center;
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 500;
    width: 100%;
}
.forgot_password_error_message{
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    color: red;
    margin-top: 6px;
}
.forgot_password_back{
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #005EB8;
    gap: 10px;
}
.forgot_password_form_field{
    margin: 15px 0px;
}