.Property_details_Titile {
  display: flex;
  flex-direction: row;
  gap: 10px;
  font-family: "Inter";
  justify-content: space-between;
}
.titile_Property {
  font-size: 22px;
}
.Share_Like{
  display: flex;
  flex-direction: row;
}

.circle_details_property {
  height: 30px;
  /* position: absolte; */
  width: 30px;
  background-color: rgb(226, 206, 206);
  border-radius: 50%;
  display: inline-block;
  /* right: 0; */
  opacity: 0.5;
}
.card_contents{
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 130px;
}
.card_view_button{
  display: flex;

}
.Gird_row{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.custom-typography {
  display: flex;
  align-items: center;
  margin-top: 8px;
}
.property_about {
  font-size: 16px;
  margin-top: 10px;
}
.price {
  color: #000000;
  font-weight: 600;
  font-size: 20px;
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
}

.text {
  color: #000000;
  font-weight: 400;
  font-size: 16px;
  display: inline-flex;
  align-items: center;
}
.Property_characteristics_container {
  display: flex;
  flex-direction: row;
  /* justify-content: space-between; */
  gap: 30px;
  width: 300;
  margin-top: 7px;
}
.FeaturedProp_container_view {
  display: flex;
  margin-top: 12px;
  flex-direction: column;
}
.Adress_column{
  display:flex;
  flex-direction: column;
  gap:5px;
}
.Property_details {
  display: flex;
  flex-direction: column;
  margin-top: 12px;
  font-family: "Inter";
  gap: 28px;
}
.Property_data {
  display: flex;
  justify-content: flex-start;
  width: 200px;
}
.Property_value {
  display: flex;
  justify-content: flex-start;
  width: 200px;
  /* Add any other styles as needed */
}
.Single_column_data{
  display: flex;
  flex-direction: column;
  gap:10px;
}
.card_titile {
  
  font-size: 18px;

  font-weight: 600;
}

.Featuredvehi_container {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 256px;
  height: 375px;
  border-radius: 8px;
  gap: 8px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.vehicle_listing__img {
  display: flex;
  /* margin-top: -12px; */
  width: 100%;
  /* border-top-left-radius: 8px;
              border-top-right-radius: 8px; */
  /* border-radius: 8px; */
}
.FeaturedProp_image {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.vehicle_listing_location {
  display: flex;
  font-size: 13px;
  color: #9093a1;
}
.vehicle_listing_date {
  display: flex;
  font-size: 14px;
}
.details_container_vehicle_listing {
  display: flex;
  flex-direction: column;
  gap: 9px;
  padding: 20px;
}
.Property_similar_rectangle {
  width: 44px;
  height: 20px;
  background-color: #005eb8;
  position: absolute;
  margin-left: -28px;
  margin-top: -40px;
  color: #ffffff;
  
  font-size: 12px;
  padding: 2px 21px;
  border-radius: 4px;
}
.Property_similar_rectangle.trending {
  background-color: #FC1551;
}

/* Blue background for popular */
.Property_similar_rectangle.popular {
  background-color: #005eb8;
}
.Property_similar_triangle-down {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -28px;
  margin-top: -19px;
  border-left: 9px solid transparent;
  border-top: 9px solid #005eb8;
}
/* Red border for trending */
.Property_similar_triangle-down.trending {
  border-top-color: #FC1551; /* Change border color for Trending */
}

/* Blue border for popular */
.Property_similar_triangle-down.popular {
  border-top-color: #005eb8; /* Change border color for Popular */
}
.vehicle_listing_ {
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -28px;
  margin-top: -19px;
  border-left: 9px solid transparent;
  border-right: 0px solid transparent;
  border-top: 9px solid #005eb8;
}
.conatiner_titile_FeaturedPropertyview {
  display: flex;
  font-size: 20px;
  font-weight: 600;
}
.main_property_view_maincontainer{
  padding: 40px 50px ;
}
.Autobot_Maincontainer {
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 0;
  z-index: 999;
}
.image_bot{
width:85px;

}
.circle {
  z-index: 9;
  height: 30px;
  position: absolute;
  width: 30px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  right: 0;
  opacity: 0.5;
}
.property_aboutbank_content{
  font-size: 16px;
  
}
.Featuredproperty_sub_container{
  display: flex;
  padding: 5px;
  gap: 28px;
  margin-top: 20px;
  flex-wrap: wrap;
}
.property_disclaimer{
  font-size: 16px;

}

.card {
  border-radius: 4px;
  padding: 20px;
  box-shadow: 1px 2px 5px rgba(0, 0, 0, 0.1);
  height: 550px;
  overflow-y: auto;
  margin-top: 30px;
}

.step {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  border:solid 1px #DBDBDB;
  border-radius: 10px;
  padding: 10px;
  margin-top: 15px;
}

.step-number {
  font-size: 24px;
  margin-right: 10px;
}

.step-title {
  font-weight: 600;
  font-size: 17px;
  margin-right: 10px;
  width: 175px;
}
.Titile_description{
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.step-description {
  flex: 1;
}

.step-icon {
  font-size: 20px;
}

.Underline_titile{
  content: "";
  display: block;
  width: 30px;
  height: 6px;
  /* background-color: #344051; */
  border-radius: 17px;
}

@media only screen and (max-width: 600px) {

.Property_data {
  
  display: flex;
  justify-content: flex-start;
  width: 130px;
  font-size: 14px;
}
.Property_value {
   
    display: flex; 
    justify-content: flex-start; 
    align-items: flex-start;
    font-size: 14px; 
    max-width: 14ch; 
    word-break: break-word;
}
.property_aboutbank_content{
  font-size: 14px;
 
}
.property_about {
  font-size: 14px;
  margin-top: 10px;
}
.conatiner_titile_FeaturedPropertyview {
  display: flex;
  font-size: 20px;
  font-weight: 600;
  margin-top: 0px;
  margin-left: 0px;
}
.property_disclaimer{
  font-size: 14px;
}
.main_property_view_maincontainer {
  padding: 30px 20px;
}
}