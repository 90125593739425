.bank_form_header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.bank_form_back_button{
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    display: flex;
    gap: 5px;
    align-items: center;
    padding: 3px 15px;
    background-color: black;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}
.page_title{
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}
.bank_form_top_action{
    margin-top: 25px; 
    display: flex;
    align-items: center;
    justify-content: end;
    margin-bottom: 10px;
}
.bank_form_back{
    color: #003087;
    border: 1px solid #003087;
    cursor: pointer;
    font-family: 'inter';
    font-size: 15px;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3px;
    padding: 5px 8px;
    width: 70px;
    /* box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px; */
    border-radius: 7.5px;
}
.bank_form_main_Container{
    margin-top: 25px;
    padding: 25px 25px;
    border-radius: 8px;
    background: #FAFDFF;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.10);
}
.bank_form_edit_details{
    display: flex;
    gap: 5;
    color: #003087;
    font-family: "Inter";
    font-size: 16px;
    justify-content: flex-end;
    align-items: center;
    font-weight: 600;
    cursor: pointer;
    padding: 5px;
}
.bank_formContainer{
   background-color: white;
   padding: 25px;
   position: relative;
   border: 1px solid #003087;
   border-radius: 10px;
}
.bank_form_legend{
    background-color: white;
    position: absolute;
    top: -14px;
    padding: 1px 8px;
    border-radius: 5px;
    color: #005EB8;
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}
.bank_form_row{
    display: grid;
    grid-template-columns: 50fr 50fr;
    column-gap: 20px;
    margin-bottom: 10px;
    margin-top: 15px;
}
.bank_form_label{
    color: #10002E;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.4px;
    margin-bottom: 6px;

}
.bank_form_row_label_field{
    display: flex;
    flex-direction: column;
}
.BankForm_Top_section{
  display: flex;
  flex-direction: row;
  gap: 20px;
}
.Bank_Inputfields_Form{
  display: block;
  width: 100%;
}
.bank_form_action_button{
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
}
.file_upload_text-bank{
    margin-top: 10px;
    display: flex;

    align-items: center;
    font-size: 14px;
    font-family: "inter";
    
}
.Attachment_formContainer_Bank {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 2px dashed #9093a1;
  border-radius: 10px;
}
.file-upload-container-bank {
    display: flex;
    justify-content: center;
    height: 60px; /* Adjust based on your layout */
  }
  .Pic_uploader{
    display: flex;
    position: absolute;
    left: 126px;
    margin-top: 85px;
  }
  .attachment_close_icon_Bank {
    position: absolute;
    top: -6px;
    left: 161px;
  }
  .file-upload-container div {
    width: 60px; /* Adjust size */
    height: 60px; /* Adjust size */
    background-color: #e0e0e0; /* Customize color */
    border-radius: 50%; /* Make it round */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer; /* Pointer cursor for clickable */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for effect */
  }
  
  .file-upload-container div:hover {
    background-color: #d0d0d0; /* Change color on hover */
  }
  
  .file-upload-container svg {
    color: #555; /* Customize icon color */
    font-size: 24px; /* Adjust icon size */
  }
  .img-div-attachment {
    margin: 10px;
    position: relative;
  }
  .attachment_close_icon {
    position: absolute;
    top: -8px;
    right: -8px;
  }
  .attachemnt_cancelIcon {
    cursor: pointer;
    border-radius: 20px;
    background-color: white;
    color: red;
  }
  .image_attachment {
    border-radius: 10px;
    width: 165px;
    height: 125px;
    background-size: cover;
  }
.file_upload_button{
    background-color: #ff5f5f; /* Red background color */
    color: white; /* White text color */
    border: none; /* Remove default border */
    padding: 10px 20px; /* Padding for size */
    font-size: 14px; /* Text size */
    border-radius: 8px; /* Rounded corners */
    cursor: pointer; /* Pointer cursor on hover */
    transition: background-color 0.3s ease, transform 0.3s ease; /* Smooth transitions */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  }
  .bank_form_attachment_label{
    color: #10002E;
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.4px;
  }
@media only screen and (max-width:600px) {
    .bank_form_row{
        grid-template-columns: auto;
        margin-bottom:0px
    }
    
}