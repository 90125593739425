.fuel_lookup_header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}
.fuel_lookup_action_chipset{
    display: flex;
    gap:5px;
    justify-content: end;
}

.add_fuel_button{
    font-family: 'Inter';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    padding: 8px 15px;
    background-color: #0F62FE;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}