.message_main_container{
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    display: flex;
    gap: 2px;
    height: 85vh;
}
.message_users_sub_container{
    margin-bottom: 5px;
    width: 400px;
    overflow: auto;
}
.message_list_sub_container{
    position: relative;
    padding: 10px;
    width: 100%;
}
.message_users_sub_container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.message_users_sub_container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}