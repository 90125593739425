.filter_tool_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.filter_tool_field_width
  .css-1gwz26b-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 2px !important;
}
.agent_Search_button {
  cursor: pointer;
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 7px 25px;
  color: white;
  font-size: 14px;
}
.agentFilter_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 2.5px 8px !important;
  font-size: 15px;
  font-family: "Inter";
  width: 183px;
  margin-top: 0px;
  height: 28px;
  border-radius: 5px;
  border: 1px solid rgba(119, 182, 243, 1) !important ;
}
.agent_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 7px 25px;
  background-color: white;
  color: #005eb8;
  font-size: 14px;
  border: 1px solid #005eb8;
}
.filter_field_container {
  position: relative;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.filter_field_container.show {
  display: block;
}
.customPlaceholder {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  letter-spacing: 0.00938em;
  font-family: 400;
  color: #bbbbbb;
}
@media only screen and (max-width: 1316px) {
  .filter_field_container {
    max-width: 200px;
    padding: 15px;
    border-radius: 5px;
    background-color: white;
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: start;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    z-index: 1;
  }
  #agent_filter_field_container {
    display: none;
  }
  .agentFilter_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 3px 8px !important;
    font-size: 15px;
    font-family: "Inter";
    width: 183px;
    margin-top: 7px;
    height: 28px;
    border-radius: 5px;
    border: 1px solid rgba(119, 182, 243, 1) !important ;
  }
}
