.page_title{
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}
.role_filter_container{
    margin: 30px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.role_type_chipset{
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: end;
}