.Agent_page_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.add_Agent_button {
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  padding: 8px 15px;
  background-color: #0f62fe;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}
.agent_search_filter_component {
  margin: 25px 0px;
}
