.property_task__view_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.property_task_view_back {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #344051;
  color: white;
  padding: 4px 15px;
  gap: 10px;
  border-radius: 5px;
}
.property_task_view_mainContainer {
  background-color: #fafdff;
  padding: 10px;
  box-shadow: 0px 4px 12px 0px #0000001a;
}
.property_task_view_details_mainContainer {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 20px;
}
.property_task_view_details_subContainer {
  display: grid;
  grid-template-columns: auto 10px auto;
}
.property_task_view_details_field_container_grid {
  display: grid;
  grid-template-columns: 200px 300px;
  margin-bottom: 10px;
}
.property_task_view_details_field_label {
  font-family: "inter";
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: black;
}
.property_task_view_details_field_value {
  font-family: "inter";
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2021;
}
.property_task_view_edit_action {
  cursor: pointer;
  display: flex;
  justify-content: end;
  color: #003087;
  font-size: 20px;
  margin-bottom: 10px;
}
.property_task_view_details_custom_dropdown {
  max-width: 200px;
}
.property_task_attachment_container {
  border: 1px dashed grey;
  border-radius: 10px;
  padding: 20px;
  margin-top: 5px;
}
.propertyTaskAttachment_edit_details {
  margin-top: 10px;
  display: flex;
  gap: 5;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.property_task_action_attachment {
  display: flex;
  justify-content: flex-end;
}
.property_Task_attachment {
  text-align: center;
  font-family: "inter";
  font-size: 14px;
  font-weight: 500;
  color: #4e4f53;
}
