.message_listing_super_container{
    flex-grow: 1;
}
.message_listing_main_container{
    margin: 5px 0px;
    padding: 20px;
    height: 67vh;
    overflow: auto;
}
.message_listing_main_input_send_container{
    display: flex;
    gap: 10px;
    align-items: center;
    /* position : relative; */
}
.message_profile_super_container{
    min-height: 40px;
}
.message_text_field{
    resize: none;
    outline: none;
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    padding: 5px;
    height: 30px;
    line-height: 30px;
}
.message_emoji_main_container{
    position: relative;
}
.message_emoji_sub_container{
    position: absolute;
    top: -470px;
}
.message_emoji{
    font-size: 30px;
}
.message_send{
    font-size: 30px;
}
.message_text_container{
    display: flex;
    margin: 5px 0px;
}
.message_text_field {
    width: 100%;
    flex-grow: 1;
    resize: none; /* Optional: prevent resizing */
  }
.message_text{
    padding: 7px 10px;
    border-radius: 10px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    flex-grow: 0;
    flex-shrink: 1;
    /* max-width: 70%; */
    padding: 10px;
    word-wrap: break-word;
    border-radius: 5px;
}
.message_date_time{
    margin-top: 5px;
    font-family: "Inter";
    font-size: 10px;
    font-weight: 400;
    color: rgb(196, 194, 194);

}
.message_helper_text{
    border: 1px solid rgb(226, 226, 226);
    border-radius: 10px;
    font-family: "Inter";
    padding: 20px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.message_listing_main_container {
    -ms-overflow-style: none;  /* Internet Explorer 10+ */
    scrollbar-width: none;  /* Firefox */
}
.message_listing_main_container::-webkit-scrollbar { 
    display: none;  /* Safari and Chrome */
}
.dummy_recording_container{
    border: 1px solid rgb(233, 233, 233);
    border-radius: 5px;
    font-family: "Inter";
    background-color: white;
    width: 100%;
    padding: 15px;
    
}
.message_audio_controller_main_container{
    display: flex;
    align-items: center;
    width: 100%;
    gap: 5px;
    border-radius: 5px;
    border: 1px solid rgb(233, 233, 233);
    padding: 4px;
}
.message_audio_controller{
    flex-grow: 1;
}
.message_audio_delete{
    color: red;
    font-size: 30px;
}

.message_input_container {
    width: 100%; /* Or any desired width */
    display: flex;
    flex-direction: column;
    position: relative;
    margin-top: -27px;
  }
  
  .error_text_msg {
    width: 100%;
    background-color: #f2f2f2;
  padding: 5px;
    color: #000; /* Or any desired color */
    height: 20px; /* Reserve space for the error message */
    visibility: hidden; /* Hide the error message initially */
  }
  
  .error_text_msg.visible {
    visibility: visible; /* Make error message visible when it has content */
  }
  audio{
    height: 35px;
  }
  /* .error_text_msg{
    display: flex;
    background-color: #e6e6e6;
    box-sizing: border-box;
    width: 98%;
    position: absolute;
    bottom: 50px;
  } */