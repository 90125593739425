.spin_container{
    z-index:99;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.7);
}

  
.loading-text {
    color: gray;
    font-family: 'inter';
    margin-top: 10px;
    animation: fade 1.5s infinite;
}


@keyframes fade {
    0% {
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }