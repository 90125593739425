.Runner_Custom_notes_main_container {
    border: 1px dashed grey;
    background-color: white;
    padding: 5px 15px;
    border-radius: 10px;
    width: 500px;
  } 
  .Runner_note_container {
    padding: 16px 12px;
    border: 1px solid rgba(230, 230, 230, 1);
    border-radius: 12px;
    font-family: "Inter";
    font-size: 15px;
    display: flex;
    flex-direction: column;
    margin-top: 18px;
  }
  
  .Runner_Time_date {
    display: flex;
    flex-direction: row;
    gap:5px;
  }
  .Runner_sub_container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    justify-content: space-between;
  }
  .Runner_Notes {
    margin-top: 20px;
  }
  .Runner_Date {
    color: rgba(144, 147, 161, 1);
    font-size: 12px;
    font-weight: 400;
    margin-left: 30px;
  }
  
  .Runner_Details_container{
    max-height: 400px;
  width: 503px;
  overflow: auto;
  }
  .Runner_No_notes{
    display: flex;
  padding: 12px 8px;
  }
  @media only screen and (max-width: 600px) {
    .Runner_Time_date {
        display: flex;
        flex-direction: column;
        gap:5px;
      }
      .Runner_Date {
        color: rgba(144, 147, 161, 1);
        font-size: 12px;
        font-weight: 400;
        margin-left: 0px;
      }
      .Runner_Custom_notes_main_container {
        border: 1px dashed grey;
        background-color: white;
        padding: 5px 5px;
        border-radius: 10px;
        width: auto;
      }
      .Runner_Details_container{
        max-height: 400px;
      width: 200px;
      overflow: auto;
      }
  }