.loginForm_MainContainer{
    border-radius: 10px;
    filter: drop-shadow(0px 5.625px 28.125px rgba(0, 0, 0, 0.16));
    background-color: white;
    padding: 60px 80px 25px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.loginForm_SubContainer1{
    display: flex;
    justify-content: center;
}
.loginText{
    margin: 15px 0px;
    text-align: center;
    font-family: 'Inter', sans-serif;
    color: #606060;
    font-size: 15px;
    font-weight: 600;
}
.BankRealityLogo{
    width: 200px;
    height: 50px;
}
.loginFormContainer_Form{
    display: flex;
    flex-direction: column;
}
.loginFormContainer_Form{
    /* margin: 30px 0px; */
}
.loginFormField_Container{
    margin:30px 0px 15px 0px ;
}
.loginform_label{
    font-family: 'Inter', sans-serif;
    color: #606060;
    margin: 10px 0px;
    font-size: 14px;
    font-weight: 600;
}
.loginForm_Copyright{
    text-align: center;
    font-family: 'Inter', sans-serif;
    color: #606060;
    font-size: 15px;
    font-weight: 500; 
    margin-top: 80px;
}
.errorText{
    padding-top: 5px;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    color: red;
}
.forget_password{
    margin: 15px 0px;
    cursor: pointer;
    text-align: end;
    text-decoration: underline;
    color:  #005EB8;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
    cursor: pointer;
}
.login_button{
    cursor: pointer;
    border: none;
    margin-top: 15px;
    padding: 13px 0px;
    border-radius: 9.224px;
    background: #003087;
    box-shadow: 0px 10px 20px 0px rgba(1, 11, 253, 0.12);
    color:  #FFF;
    text-align: center;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 400;
    width: 100%; /*newly added*/
}
.loginForm_MainContainer .MuiOutlinedInput-notchedOutline{
    border-color: #77B6F3 !important;
}
.loginForm_MainContainer .MuiFormLabel-root.Mui-focused {
    color: #77B6F3 !important;
  }
.loginForm_MainContainer .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
    border-color: #77B6F3 !important;
  }
::-ms-reveal {
    display: none;
}
.loginForm_LanguageToggle{
    position: absolute;
    top: 0;
    right: 0;
}

@media only screen and (max-width: 600px) {
    .loginForm_MainContainer{
        width: 300px;
        padding: 60px 25px 25px;
    }
}
@media only screen and (min-width: 601px) {
    .loginForm_MainContainer{
        width: 300px;
    }
} 

@media only screen and (min-width: 992px) {
    .loginForm_MainContainer{
        width: 400px;
    }
} 
