.Custom_attachment_main_container {
  margin-top: 25px;
  border: 1px dashed grey;
  background-color: white;
  padding: 25px;
  border-radius: 10px;
}
.attachment_title {
  margin-bottom: 20px;
  font-family: "Inter";
}
.input_file_container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.file_upload_icon {
  color: blue;
}
.choose_file_field {
  font-size: 14px;
  color: gray;
  min-width: 150px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 9px 8px;
  border: 1px solid darkgrey;
  border-radius: 5px;
}
.attachment_main_container {
  margin-top: 30px;
  display: flex;
  gap: 12px;
  flex-wrap: wrap;
  position: relative;
}
.attachment_container {
  position: relative;
}
.attachment_close_icon {
  position: absolute;
  top: -8px;
  right: -8px;
}
.attachemnt_cancelIcon {
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  color: red;
}
.image_attachment {
  border-radius: 10px;
  width: 150px;
  height: 80px;
  background-size: cover;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 1px;
  padding: 12px 7px 3px;
}
.attachment_sub_container {
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
}
.attachment_sub_container div {
  width: 200px;
}
.file_attachment {
  width: 40px;
  height: 50px;
}
.file_attachment_container {
  border-radius: 5px;
  position: relative;
  background: #f5f7ff;
  padding: 9px 15px;
}
.file_title {
  color: gray;
  font-size: 13px;
}
.file_size {
  font-size: 10px;
  color: gray;
}
.Custom_attachment_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {
  .choose_file_field {
    max-width: 50px;
    display: flex;
    font-size: 14px;
    color: gray;
    padding: 9px 8px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
  }
  .input_file_container {
    display: flex;
    gap: 20px;
  }
  .attachment_sub_container div {
    width: 100px;
  }
  .image_attachment {
    border-radius: 10px;
    width: 120px;
    height: 80px;
    background-size: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 1px;
    padding: 12px 7px 3px;
    margin-left: -17px;
  }
  .file_attachment_container {
    border-radius: 5px;
    position: relative;
    background: #f5f7ff;
    padding: 9px 15px;
    width: 67px;
    margin-left: -2px;
  }
}
@media only screen and (max-width: 600px) {
  .choose_file_field {
    max-width: 50px;
    display: flex;
    font-size: 14px;
    color: gray;
    padding: 9px 8px;
    border: 1px solid #c0c0c0;
    border-radius: 5px;
  }
  .input_file_container {
    display: flex;
    gap: 20px;
  }
  .attachment_sub_container div {
    width: 100px;
  }
  .image_attachment {
    border-radius: 10px;
    width: 100px;
    height: 80px;
    background-size: cover;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 1px;
    padding: 12px 7px 3px;
    margin-left: -17px;
  }
  .file_attachment_container {
    border-radius: 5px;
    position: relative;
    background: #f5f7ff;
    padding: 9px 15px;
    width: 67px;
    margin-left: -2px;
  }
}
