.property_task_attachment_title {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  margin-bottom: 20px;
}
.property_task_attachment_main_container {
  display: flex;
  flex-direction: column-reverse;
  gap: 30px;
}
.property_task_attachment_sub_container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  align-items: center;
}
.property_task_image_attachment_container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-top: "10px";
}
.property_task_image_attachment {
  border-radius: 10px;
  width: 135px;
  height: 80px;
  margin-top: "10px";
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 1px;
  padding: 12px 7px 3px;
}
.property_task_image_title {
  margin-top: 10px;
  text-align: center;
}
.property_task_file_attachment_container {
  display: flex;
  align-items: center;
  gap: 5px;
}
.property_task_file_attachment_sub_container {
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.property_task_publish_action_checkbox {
  display: flex;
  align-items: center;
  gap: 10px;
}
.property_task_publish_action_footer {
  margin-top: 75px;
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: end;
}
