.PropertieForm_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.back_button {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  display: flex;
  gap: 5px;
  align-items: center;
  padding: 3px 15px;
  background-color: black;
  color: white;
  border-radius: 5px;
}
.Propertie_tabs {
  margin-top: 20px;
  background-color: #e9f2ff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.mui_tab_style {
  text-transform: none !important;
  font-family: "Inter" !important;
  font-size: 17px !important;
  font-style: normal !important;
  font-weight: 600 !important;
  line-height: normal !important;
}
