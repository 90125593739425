.advance_table_mainContainer
  .MuiTableContainer-root
  .MuiTableBody-root
  .MuiTableRow-root
  .MuiTableCell-root:first-of-type {
  position: sticky;
  left: 0;
  z-index: 1;
  background-color: white;
}
.sticky-actions4 {
  position: sticky;
  right: 0;
  box-shadow: inset 1px 0px 0px rgb(235 235 235); /* CSS3 */
  margin: -1px;
  padding: 12px;
  min-width: 100px;
}
.sticky-actions3 {
  position: sticky;
  right: 0;
  -moz-box-shadow: 0 0 10px 1px #6dc421;
  -moz-box-shadow: inset 2px 2px 5px #202020; /* Firefox */
  -webkit-box-shadow: inset 1px 0px 0px rgb(235 235 235);
  box-shadow: inset 1px 0px 0px rgb(235 235 235);
  margin: -1px;
  padding: 12px;
  min-width: 100px;
}
