
.note_Form{
    width: 500px;
}
.note_form_label{
    font-size: 15px;
    margin: 8px 0px;
    font-weight: 500;
    font-family: "Inter";
}
.note_form_action{
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 5px;
}
.note_form_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 5px 8px !important;
    font-size: 15px;
    font-family: "Inter";
}
@media only screen and (max-width:615px) {
    .note_Form{
        width: 300px;
    }
}
@media only screen and (max-width:415px) {
    .note_Form{
        width: 200px;
    }
}