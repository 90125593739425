.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.propertyType_add_sort_container {
  margin: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.property_type_chipset {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: flex-end;
  min-width: 170px;
}
.img-div-attachment {
  margin: 10px;
  position: relative;
}
.attachment_close_icon {
  position: absolute;
  top: -8px;
  right: -8px;
}
.attachemnt_cancelIcon {
  cursor: pointer;
  border-radius: 20px;
  background-color: white;
  color: red;
}
.image_attachment {
  border-radius: 10px;
  width: 150px;
  height: 80px;
  background-size: cover;
}
.file_upload_text{
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-family: "inter";
  
}
.file-upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px; /* Adjust based on your layout */
}

.file-upload-container div {
  width: 60px; /* Adjust size */
  height: 60px; /* Adjust size */
  background-color: #e0e0e0; /* Customize color */
  border-radius: 50%; /* Make it round */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; /* Pointer cursor for clickable */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Optional shadow for effect */
}