.Financialform_mainContainer{
    width: 500px;
}
.Financialform_field_title{
    font-size: 14px;
    margin: 5px 0px;
    font-weight: 500;
}
.Financialform_form_action{
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
}
.update_financial_Form_label {
   
  }