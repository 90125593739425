.notification_main_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 10px;
  padding: 10px;
  margin: 5px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 10px;
}
.notification_email_dateTime {
  align-items: center;
  gap: 5px;
}
.notification_email {
  font-size: 12px;
  font-weight: bold;
}
.notification_dateTime {
  font-size: 10px;
  color: gray;
  white-space: nowrap;
  padding: 15px;
}
.notification_message {
  margin-top: 10px;
  font-size: 14px;
}
.main_not_notification_container{
  
  width: 100%;
  height: 100%;
  /* overflow: hidden; */
}
.no_notification_image{
  width: 30%;
  height: 40%;
  margin-bottom: 25px;
}
.not_noti_image_with_container{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* background-color: rgb(30, 153, 153); */
}
.main_text{
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  margin: 10px 0px;
}
.sub_text{
  font-size: 15px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
}
.not_found_go_back_button{
  background-color: #1755E7;
  color: white;
  padding: 8px 20px;
  border: none;
  border-radius: 5px;
  font-size: 17px;
  font-weight: bold;
  cursor: pointer;
}
.no_Notification_centralize_content{
  display: flex;
  justify-content: center;
  align-items: center;
}
.custom_circle_1{
  top: 50%;
  transform: translate(-60%, -50%);
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 175px;
  background: linear-gradient(180deg, rgba(214, 230, 255, 0.8) 0%, rgba(0, 125, 254, 0.8) 97.92%, rgba(0, 0, 205, 0) 100%);
}
.custom_circle_2{
  bottom: -237px;
  right: -105px;
  /* transform: translate(-0%, -0%); */
  position: absolute;
  width: 350px;
  height: 350px;
  border-radius: 175px;
  background: linear-gradient(180deg, rgba(214, 230, 255, 0.8) 0%, rgba(0, 125, 254, 0.8) 97.92%, rgba(0, 0, 205, 0) 100%);
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .no_notification_image{
      width: 50%;
      height: 50%;
 
  }
}

/* Small devices (portrait tablets and large phones, 600px and up)
@media only screen and (min-width: 609px) {
  .example {background: green;}
} */

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .no_notification_image{
      width: 40%;
      height: 50%;
    
  }
} 

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .no_notification_image{
      width: 40%;
      height: 50%;
      /* margin-bottom: 25px; */
  }
} 

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1095px) {
  .no_notification_image{
      width: 40%;
      height: 50%;
      /* margin-bottom: 25px; */
  }
}
