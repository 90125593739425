.homePage_main_container {
  width: 100vw;
  height: 100vh;
  display: flex;
}
.homePage_header_outlet_container {
  flex: 1;
  max-width: 100vw;
}
.homePage_main_content_container {
  background-color: white;
  width: 100%;
  height: calc(100% - 66px);
}
.homePage_main_content_container_outlet {
  padding: 10px;
}
