.vehicle_task_attachment_main_base_container{
    /* margin-top: 25px; */

}
.vehicle_task_attachment_title{
    font-family: 'Inter';
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-bottom: 20px;
}
.vehicle_task_attachment_main_container{
    display: flex;
    flex-direction: column-reverse;
    gap: 30px;
}
.vehicle_task_attachment_sub_container{
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
}
.vehicle_task_image_attachment_container{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
}
.vehicle_task_image_attachment{
    border-radius: 10px;
    width: 135px;
    height: 80px;
    margin-top: 12px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 4px 1px;
    padding: 12px 7px 3px;
}
.vehicle_task_image_title{
    margin-top: 10px;
    /* margin-left: 25px; */
    text-align: center;
}
.vehicle_task_file_attachment_container{
    display: flex;
    align-items: center;
    gap: 5px;
}
.vehicle_task_file_attachment_sub_container{
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 5px
}
.vehicle_task_publish_action_checkbox{
    display: flex;
    align-items: center;
    gap: 10px;
}
.vehicle_task_publish_action_footer{
    margin-top: 75px;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: flex-end;
}