.SubContainer_details_main_container_Image {
    display: flex;
    /* justify-content: center; */
  }

.Testimonial_form_row {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 25px;
  margin-top: 23px;
  margin-bottom: 17px;
}
.Testimonial_form_Textarea{
    display: grid;

    column-gap: 25px;
    margin-bottom: 17px;  
}