.updatePassword_Form {
  width: 500px;
}
.updatePassword_form_label {
  font-size: 15px;
  margin: 8px 0px;
  font-weight: 500;
  font-family: "Inter" !important;
}
.updatePassword_form_input
  .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px 8px !important;
  font-size: 15px !important;
  font-family: "Inter" !important;
}
.updatePassword_form_input
  .css-19qh8xo-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 7px 8px !important;
  font-size: 15px !important;
  font-family: "Inter" !important;
}

@media only screen and (max-width: 615px) {
  .updatePassword_Form {
    width: 300px;
  }
}
@media only screen and (max-width: 415px) {
  .updatePassword_Form {
    width: 200px;
  }
}
.changePassword_form_action {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}
