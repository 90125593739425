.Custom_notes_main_container {
  margin-top: 25px;
  border: 1px dashed grey;
  background-color: white;
  padding: 5px 5px;
  border-radius: 10px;
}
.Navbar {
  display: flex;
  width: 98%;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 12px 15px;
  justify-content: space-between;
}
.Titile_notes {
  font-family: "Inter";
  font-size: 16px;
  display: flex;
  gap: 15px;
  padding: 7px;
  font-weight: 500;
}
.note,
.activity {
  color: black; /* Default color */
  cursor: pointer;
}

.note.active {
  color: rgba(0, 133, 255, 1);
}

.activity.active {
  color: rgba(0, 133, 255, 1);
}
.note:hover,
.activity:hover {
  color: rgba(0, 133, 255, 1);
  cursor: pointer;
}
.edit_icon:hover,
.delete_icon:hover {
  cursor: pointer;
}
.note_container {
  padding: 16px 12px;
  border: 1px solid rgba(230, 230, 230, 1);
  border-radius: 12px;
  font-family: "Inter";
  font-size: 15px;
  display: flex;
  flex-direction: column;
  margin-top: 18px;
}
.note_icons {
  gap: 10px;
  display: flex;
}
.Time_date {
  display: flex;
  flex-direction: row;
  gap:5px;
}
.sub_container {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
}
.Notes {
  margin-top: 20px;
}
.Details_container{
  max-height: 165px;
  overflow: auto;
}
.No_notes{
  display: flex;
padding: 12px 8px;
}
@media only screen and (max-width: 600px) {
  .Navbar {
    display: flex;
    width: 93%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    padding: 12px 15px;
    justify-content: space-between;
  }
}
