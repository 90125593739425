.login_page_main_container{
    display: flex;
}
.login_page_imageContainer{
    position: relative;
    background: linear-gradient(180deg, #0575E6 0%, #02298A 84.79%, #021B79 100%);
    width: 40%;
    height: 100vh;
    overflow: hidden;
}
.login_backgroundImage{
    width: 100%;
    height: 100vh;
    overflow: hidden;
}
.imagecontainer_subtitle{
    position: absolute;
    bottom: 40px;
    color: white;
    font-size: 15px;
    font-weight: 400;
    font-family: 'Inter';
    left: 50%;
    transform: translate(-50%,0%);
}
.login_page_loginFormContainer{
    position: relative;
    flex: 1;
}
@media only screen and (max-width: 782px) {
    .login_page_imageContainer{
       display: none;
    }
    .login_page_loginFormContainer{
        position: static;
    }
  }