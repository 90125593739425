.vehicleslider_buttons {
  display: flex;
  margin-top: 49px;
  background-color: #f1f1f1;
  color: #000000;
  height: 81px;
  width: 31px;
  margin-left: "12px";
  border-radius: 3;
}
.vehicleslider_backbuttons {
  display: flex;
  margin-top: 52px;
  background-color: #f1f1f1;
  color: #000000;
  height: 80px;
  width: 31px;
}
.image_flex {
  width: 100%;
  height: 517px;
  display: flex;
}
.image_mobile_flex {
  display: none;
}
.image_flex_ipad {
  display: none;
}
.image_flex_ipad {
  display: none;
}
.container_image {
  display: none;
}
.container_web {
  display: block;
}
.vehicleslider_backbuttonsss {
  display: flex;
background-color: #f1f1f1;
color: #000000;
height: 80px;
width: 25px;
}
.vehicleslider_buttonsss{
  display: flex;
  background-color: #f1f1f1;
  color: #000000;
  height: 80px;
  width: 25px;

}
.slider_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  background-color: white;
  margin-top: 40px;
}
@media only screen and (max-width: 600px) {
  .image_mobile_flex {
    display: block;
  }
  .image_flex {
    display: none;
  }
  .image_flex_ipad {
    display: none;
  }

  .container_image {
    display: block;
  }
  .container_web {
    display: none;
  }
  .slider_container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background-color: white;
    margin-top: 25px;
  }
  .vehicleslider_buttons {
    display: flex;
  margin-top: 25px;
  background-color: #f1f1f1;
  color: #000000;
  height: 80px;
  width: 25px;
  }
  .vehicleslider_backbuttons {
    display: flex;
  margin-top: 25px;
  background-color: #f1f1f1;
  color: #000000;
  height: 80px;
  width: 25px;
  }
  .image_flex_ipad {
    display: none;
  }
}

@media only screen and (max-width: 850px) {
  .image_flex_ipad {
    display: block;
  }
  .image_mobile_flex {
    display: none;
  }
  .image_flex {
    display: none;
  }
  .vehicleslider_backbuttons {
    display: flex;
  margin-top: 25px;
  background-color: #f1f1f1;
  color: #000000;
  height: 80px;
  width: 45px;
  }
}
