.vehicleTask_choose_file_button {
  border: none;
  background-color: #e2e8f0;
  color: #64748b;
  font-size: 12px;
  padding: 5px;
}
.vehicleTask_choose_file_field {
  cursor: pointer;
  font-size: 14px;
  color: gray;
  min-width: 200px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 7px 8px;
  border: 1px solid darkgrey;
  border-radius: 5px;
}
.vehicleTask_choose_file_files {
  padding: 2px;
  margin: 5px;
  border-radius: 5px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.272);
}
.vehicle_task_form_main_Container {
  margin-top: 20px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.vehicle_task_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
}
.vehicle_task_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.vehicle_taskform_fields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 40px;
  padding: 15px;
}

.vehicle_taskform_field_container {
  margin-bottom: 15px;
}
#vehicle_form_item2 {
  position: relative;
  bottom: 2.5px;
}

.vehicle_taskform_field_container-date-main {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.vehicle_taskform_fields_labels {
  color: #10002e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}
.vehicle_taskform_action {
  margin-top: 20px;
  display: flex;
  font-size: 16px;
  justify-content: flex-end;
  gap: 10px;
}
.vehicle_taskform_dualfields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 5px;
}
.vehicle_task_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.vehicle_attachment_conatiner {
  grid-column: span 2;
  border: 1px dashed gray;
  border-radius: 10px;
  padding: 10px;
}
.vehicle_page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
#vehicle_taskform {
  max-height: 63vh;
  overflow: auto;
}
@media screen and (max-width: 1159px) {
  .vehicle_taskform_fields {
    grid-template-columns: 3fr;
  }
  .vehicle_attachment_conatiner {
    grid-column: span 1;
  }
  .vehicle_taskform_field_container-date-main {
    display: flex;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    flex-direction: column;
  }
}
