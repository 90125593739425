.LiquidityForm_mainContainer{
    width: 500px;
}
.LiquidityForm_field_title{
    font-size: 14px;
    margin: 8px 0px;
    font-weight: 500;
}
.LiquidityForm_form_action{
    margin-top: 10px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
}