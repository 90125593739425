.property_choose_file_button {
  border: none;
  background-color: #e2e8f0;
  color: #64748b;
  font-size: 12px;
  padding: 5px;
}
.property_choose_file_field {
  cursor: pointer;
  font-size: 14px;
  color: gray;
  min-width: 200px;
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 7px 8px;
  border: 1px solid darkgrey;
  border-radius: 5px;
}
#property_taskform {
  max-height: 60vh;
  overflow: auto;
}
.Property_titile {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.property_task_form_main_Container {
  margin-top: 20px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.property_task_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
}
.property_task_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.property_taskform_field_container {
  margin-bottom: 15px;
}
.property_taskform_fields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
}
.property_taskform_fields_labels {
  color: #10002e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}
.property_taskform_action {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.property_taskform_dualfields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 5px;
}
.property_task_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.property_attachment_conatiner {
  border: 1px dashed gray;
  border-radius: 10px;
  padding: 10px;
}

@media screen and (max-width: 1159px) {
  .property_taskform_fields {
    grid-template-columns: 3fr; 
  }
  .property_taskform_dualfields {
    display: flex;
    flex-direction: column;

    gap: 20px;
  }
}
