.main_not_found_container{
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.not_found_image{
    width: 800px;
    height: 500px;
    margin-bottom: 25px;
}
.not_found_image_with_container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: rgb(30, 153, 153); */
}
.main_text{
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    margin: 10px 0px;
}
.sub_text{
    font-size: 15px;
    font-weight: 600;
    text-align: center;
    margin-bottom: 20px;
}
.not_found_go_back_button{
    background-color: #1755E7;
    color: white;
    padding: 8px 20px;
    border: none;
    border-radius: 5px;
    font-size: 17px;
    font-weight: bold;
    cursor: pointer;
}
.not_found_centralize_content{
    display: flex;
    justify-content: center;
    align-items: center;
}
.custom_circle_1{
    top: 50%;
    transform: translate(-60%, -50%);
    position: absolute;
    width: 350px;
    height: 350px;
    border-radius: 175px;
    background: linear-gradient(180deg, rgba(214, 230, 255, 0.8) 0%, rgba(0, 125, 254, 0.8) 97.92%, rgba(0, 0, 205, 0) 100%);
}
.custom_circle_2{
    bottom: -237px;
    right: -105px;
    /* transform: translate(-0%, -0%); */
    position: absolute;
    width: 350px;
    height: 350px;
    border-radius: 175px;
    background: linear-gradient(180deg, rgba(214, 230, 255, 0.8) 0%, rgba(0, 125, 254, 0.8) 97.92%, rgba(0, 0, 205, 0) 100%);
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .not_found_image{
        width: 475px;
        height: 488px;
        margin-bottom: 25px;
    }
  }
  
  /* Small devices (portrait tablets and large phones, 600px and up)
  @media only screen and (min-width: 609px) {
    .example {background: green;}
  } */
  
  /* Medium devices (landscape tablets, 768px and up) */
  @media only screen and (min-width: 768px) {
    .not_found_image{
        width: 500px;
        height: 400px;
        margin-bottom: 25px;
    }
  } 
  
  /* Large devices (laptops/desktops, 992px and up) */
  @media only screen and (min-width: 992px) {
    .not_found_image{
        width: 700px;
        height: 400px;
        margin-bottom: 25px;
    }
  } 
  
  /* Extra large devices (large laptops and desktops, 1200px and up) */
  @media only screen and (min-width: 1095px) {
    .not_found_image{
        width: 800px;
        height: 500px;
        margin-bottom: 25px;
    }
  }