.customTable {
    margin: 10px auto;
  }
  .simpleTable_mainContainer {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
    max-height: 65vh;
  }
  .table_pagination_container {
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .table_pagination_button {
    cursor: pointer;
    border-radius: 5px;
    border: none;
    padding: 5px 8px;
    background-color: white;
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.16));
    font-family: "Inter";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .Simple_table_pagination
    .css-r93niq-MuiButtonBase-root-MuiPaginationItem-root.Mui-selected {
    color: #fff;
    background-color: #1976d2;
  }
  .noData_Container {
    position: relative;
    width: 100%;
    height: 200px;
    text-align: center;
  }
  .noData_text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .textcontainer {
    min-width: 100px;
    width: auto;
    overflow-y: hidden;
  }
  
  @media only screen and (max-width: 390px) {
    .customTable {
      width: 350px;
      overflow: auto;
    }
  }
  