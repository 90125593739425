.homePageSlider_main_container {
  height: 100vh;
  background-color: #003087;
  padding-right: 2px;
}
.slider_logo {
  margin-top: 17px;
  margin-left: 21px;
  width: 209px;
  height: 51px;
}
.slider_Responsive_logo {
  margin-top: 17px;
  margin-left: 21px;
  width: 40px;
  height: 40px;
}
.homePageSlider_menu_section {
  margin-top: 20px;
  max-height: 87vh;
  overflow-y: auto;
}
.homePageSlider_menu_section::-webkit-scrollbar {
  width: 5px;
  background-color: #2f4d9b;
}

.homePageSlider_menu_section::-webkit-scrollbar-thumb {
  background: white;
  border-radius: 5px;
}
.homePageSlider_resposiveClose {
  border-radius: 5px;
  margin: 5px;
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  background-color: #fdfdfd;
}
.sidebar_CloseIcon {
  margin-top: 3px;
  color: #2f4d9b;
  font-size: 24px;
}

@media only screen and (max-width: 1000px) {
  .homePageSlider_resposiveClose {
    display: block;
  }
  .homePageSlider_main_container {
    z-index: 999;
    position: absolute;
    overflow-y: auto;
    width: 250px;
    background-color: #003087;
  }
  .homePageSlider_main {
    display: block;
    z-index: 999;
    position: absolute;
    width: 100vw;
    height: 100vh;
    background-color: #0d0c0c7d;
  }
}

@media only screen and (width>1000px) {
  .homePageSlider_main_container {
    display: block;
  }
  .homePageSlider_main {
    display: none;
  }
}
