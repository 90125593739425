.update_property_task_assigne_Form {
  width: 500px;
}
.update_property_task_assigne_Form_label {
  font-size: 14px;
  margin: 8px 0px;
  font-weight: 500;
  margin-top: 17px;
  font-family: "Inter";
}

@media only screen and (max-width: 615px) {
  .update_property_task_assigne_Form {
    width: 300px;
  }
}
@media only screen and (max-width: 415px) {
  .update_property_task_assigne_Form {
    width: 200px;
  }
}
.update_property_task_assigne_Form_actions {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}
