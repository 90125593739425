.PropertyAppraisal_form_main_Container {
  margin-top: 20px;
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.PropertyAppraisal_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
  margin: 30px 0px;
}
#PropertyAppraisalform {
  max-height: 62vh;
  overflow: auto;
}
.PropertyAppraisal_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.PropertyAppraisalform_field_container {
  margin-bottom: 15px;
}
.PropertyAppraisalform_fields {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
}
.PropertyAppraisalform_field_container .Mui-disabled {
  background-color: #f8f8f8;
}
.PropertyAppraisalform_fields_labels {
  color: #10002e;
  font-feature-settings: "clig" off, "liga" off;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.4px;
  margin-bottom: 5px;
}
.PropertyAppraisalform_action {
  margin-top: 20px;
  display: flex;
  justify-content: end;
  gap: 5px;
}
.PropertyAppraisalform_edit_details {
  display: flex;
  gap: 5;
  color: #003087;
  font-family: "Inter";
  font-size: 16px;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  padding: 5px;
}

@media screen and (max-width: 600px) {
  .PropertyAppraisalform_fields {
    grid-template-columns: 1fr;
  }
}
