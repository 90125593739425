.agent_dashboard_main_container{
    background-color: white;
}
.agent_dashboard_sub_listing_container_1{
    display: grid;
    grid-template-columns: auto auto auto auto auto auto;
    margin: 35px 0px;
}
.agent_dashboard_sub_listing_container_3{
    margin: 40px 0px;
  
}
.agent_dashboard_sub_item_container_3_1{
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px;
    margin: 0px 5px;
    padding: 20px;
}
.agent_dashboard_sub_item_container_3_2{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}
.agent_dashboard_sub_item_container_overview_title{
    font-family: "Inter";
    font-size: 18px;
    font-weight: 600;
}
.agent_dashboard_sub_item_container_3_3{
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
}
.agent_dashboard_sub_item_tag_3_3{
    border: 2px solid #1976d2;
    font-family: "Inter";
    font-size: 13px;
    font-weight: 500;
    border-radius: 5px;
    padding: 5px;
    cursor: pointer;
}
.overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}
.blur {
    filter: blur(5px);
    pointer-events: none;
}
.no-data-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(255, 255, 255, 0.7); /* Adjust opacity as needed */
    z-index: 1;
    font-size: 1.5em;
    text-align: center;
    pointer-events: none; /* Ensure the overlay doesn't block interactions with underlying elements */
}

.graph-container {
    position: relative;
    overflow: hidden; /* Ensure the overlay fits within the container */
    padding: 20px; /* Adjust as necessary */
}
