.Custom_Chip_main_container{
    letter-spacing: 1px;
    white-space: nowrap;
    text-align: center;
    cursor: pointer;
    padding: 4px 8px;
    font-family: 'Inter';
    line-height: 16px;
    font-weight: 500;
    font-size: 13px;
    min-width: 30px;
    border-radius: 5px
}