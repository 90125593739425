.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.vehicleType_add_sort_container {
  margin: 30px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sort_title {
  font-family: "Inter";
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}
.vehicleType_input_add_container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 3px;
}
.vehicleType_sort_select_container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.vehicleType_Input {
  outline: none;
  border-radius: 5px;
  border: 1px solid silver;
  padding: 10px 15px;
  width: 220px;
  font-size: 15px;
}
.vehicleType_sort_select
  .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  background-color: white;
  padding: 5px 8px !important;
  font-size: 15px;
}

.vehicle_type_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 5px 8px !important;
  font-size: 15px;
}
.filter_action_button {
  display: flex;
  gap: 3px;
}
.vechicle_type_chipset {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-end;
  min-width: 170px;
}
