.page_title {
  font-family: "Inter";
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.user_form_top_action {
  margin-top: 25px;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-bottom: 10px;
}
.user_form_back {
  color: #fff;
  border: 1px solid #003087;
  cursor: pointer;
  font-family: "inter";
  font-size: 15px;
  background: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  padding: 5px 8px;
  width: 70px;
  border-radius: 7.5px;
}
.user_form_main_Container {
  padding: 25px;
  border-radius: 8px;
  background: #fafdff;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.1);
}
.user_formContainer {
  background-color: white;
  padding: 25px;
  position: relative;
  border: 1px solid #003087;
  border-radius: 10px;
}
.user_form_legend {
  background-color: white;
  position: absolute;
  top: -14px;
  padding: 1px 8px;
  border-radius: 5px;
  color: #005eb8;
  font-family: "Inter";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}
.user_form_row {
  display: grid;
  grid-template-columns: 50fr 50fr;
  column-gap: 20px;
  margin-bottom: 10px;
}
.user_form_label {
  color: #10002e;
  font-family: "Inter";
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 125% */
  letter-spacing: 0.4px;
  margin-bottom: 3px;
}
.user_form_row_label_field {
  display: flex;
  flex-direction: column;
}
.user_form_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input {
  padding: 6.5px 14px !important;
  font-size: 15px !important;
  height: 25px !important;
  font-family: "Inter" !important;
}

.user_form_multinput .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 5px 8px !important;
  font-size: 15px !important;
}
.user_form_autoinput .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
  padding: 6px 8px !important;
  font-size: 15px !important;
}
.user_field_width1 .MuiAutocomplete-option {
  min-height: 0px !important;
}
.css-gdh49b-MuiAutocomplete-listbox .MuiAutocomplete-option {
  min-height: 0px !important;
}
.user_form_action_button {
  margin-top: 10px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: end;
}

@media only screen and (max-width: 600px) {
  .user_form_row {
    grid-template-columns: auto;
    margin-bottom: 0px;
  }
}
