.property_task_filter_tool_bar {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.property_task_filter_tool_field_widt
  .css-1gwz26b-MuiAutocomplete-root
  .MuiOutlinedInput-root {
  padding: 2px !important;
}
.property_task_filter_tool_field_width .MuiAutocomplete-option {
  min-height: 0px !important;
}
.Property_task_custom_date_picker_field {
  width: 200px;
  position: relative;
  border: 0.5px solid rgba(119, 182, 243, 1);
  padding: 8px 12px;
  border-radius: 5px;
  font-size: 15px;
  display: flex;
  height:19px;
  justify-content: space-between;
  overflow: hidden;      /* Ensure content is clipped */
  white-space: nowrap;   /* Prevent text from wrapping */
  text-overflow: ellipsis;
} 
.custom_date_picker_container {
  margin-top: 5px;
  position: absolute;
  display: none;
  z-index: 9999;
}
.toolbar_Search_button {
  cursor: pointer;
  font-family: "inter";
  border: none;
  background-color: #0f62fe;
  border-radius: 5px;
  padding: 7px 25px;
  color: white;
  font-size: 14px;
}
.toolbar_Reset_button {
  cursor: pointer;
  font-family: "inter";
  border-radius: 5px;
  padding: 7px 25px;
  background-color: white;
  color: #005eb8;
  font-size: 14px;
  border: 1px solid #005eb8;
}
.propertytask_input {
  padding: 3px 8px !important;
  font-size: 15px;
  font-family: "Inter";
  width: 195px;
  margin-top: -1px;
  height: 30px;
  border-radius: 4px;
  border: 1px solid rgba(119, 182, 243, 1) !important ;
}
.customPlaceholder {
  font-family: "Roboto", "Helvetica", "Arial", "sans-serif";
  letter-spacing: 0.00938em;
  font-family: 400;
  color: #bbbbbb;
}
@media only screen and (max-width: 1316px) {
  .Property_task_custom_date_picker_field {
    width: 173px ;
    position: relative;
    font-family: "Inter";
    border: 1px solid rgba(119, 182, 243, 1);
    padding: 8px 12px;
    border-radius: 5px;
    font-size: 14px;
    display: flex;
    justify-content: space-between;
  }
  
  .custom-date-picker_task.rdrDateRangePickerWrapper {
    user-select: none;
    width: 350px;
    height: auto;
    overflow-x: auto;
    display: flex;
    flex-direction: row-reverse;
  }
}