.condition_header_container{
    text-align: center;
}
.condition_title{
    font-size: 25px;
    font-weight: bold;
    margin-bottom: 10px;
}
.condition_sub_title{
    font-size: 18px;
    font-weight: bold;
    color: red;
    margin-bottom: 10px;
}
.terms_and_conditions_text{
    display: flex;
    gap: 2px;
    margin-bottom: 10px;
}