.vehicle_task__view_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.vehicle_task_view_back {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #344051;
  color: white;
  padding: 4px 15px;
  gap: 10px;
  border-radius: 5px;
}
.vehicle_task_view_mainContainer {
  background-color: #fafdff;
  padding: 10px;
  box-shadow: 0px 4px 12px 0px #0000001a;
}
.vehicle_task_view_details_mainContainer {
  background-color: white;
  border-radius: 5px;
  border: 1px solid #e5e5e5;
  padding: 20px;
}
.vehicle_task_view_details_subContainer {
  display: grid;
  grid-template-columns: 2fr 2fr;
  justify-content: center;
}

#vtvdgc1 {
  border-right: 1px solid rgb(173, 173, 173);
}
#vtvdgc2 {
  border-left: 1px solid rgb(173, 173, 173);
}
.vehicle_task_view_details_field_container_grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 10px;
  margin-left: 10px;
}
.vehicle_task_view_details_field_label {
  font-family: "inter";
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2021;
}
.vehicle_task_view_details_field_value {
  font-family: "inter";
  font-size: 15px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #1f2021;
}
.vehicle_task_view_edit_action {
  cursor: pointer;
  display: flex;
  justify-content:flex-end;
  color: #003087;
  font-size: 20px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 1400px) {
  #vtvdgc1 {
    border-right: 1px solid transparent;
  }
  #vtvdgc2 {
    border-left: 1px solid transparent;
  }
  .vehicle_task_view_details_subContainer {
    display: grid;
    grid-template-columns: 1fr;
  }
  .vehicle_task_view_details_field_label {
    text-align: left;
  }
}
@media only screen and (max-width: 999px) {
  .vehicle_task_view_details_field_container_grid {
    grid-template-columns: 150px 1fr;
    margin-bottom: 10px;
  }
}

.vehicle_task_view_details_custom_dropdown {
  max-width: 200px;
}
.vehicle_task_attachment_container {
  border: 1px dashed grey;
  border-radius: 10px;
  padding: 20px;
  margin-top: 5px;
}
.vehicle_notes_attachment_container {
  border: 1px dashed grey;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}
.VehicleTaskAttachment_edit_details {
  margin-top: 10px;
  display: flex;
  gap: 5;
  color: #003087;
  justify-content: flex-end;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
}
.vehicle_task_action_attachment {
  display: flex;
  justify-content: flex-end;
}
.vehicle_Task_attachment {
  text-align: center;
  font-family: "inter";
  font-size: 14px;
  font-weight: 500;
  color: #4e4f53;
}
@media only screen and (max-width: 400px) {
  .vehicle_task_view_details_field_label {
    text-align: left;
    margin-left: 12px;
  } 
  .vehicle_task_view_details_field_value {
    font-family: "inter";
    font-size: 15px;
    font-weight: 400;
    letter-spacing: 0em;
    text-align: left;
    color: #1f2021;
    max-width: 15ch; /* Set maximum width to 15 characters */
    word-wrap: break-word;
  }
}
