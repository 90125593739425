.page_title{
    font-family: 'Inter';
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal
}
.lookUp_list_tags{
    display: flex;
    align-items: center;
    gap: 10px;
    margin: 15px 0px;
}
.lookUp_tag{
    cursor: pointer;
    border: 1px solid rgb(201, 199, 199);
    padding: 5px 18px;
    border-radius: 5px;
    font-family: 'Inter';
    font-size: 15px;
    font-weight: 500;
}