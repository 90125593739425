
.userProfile_form_row{
    display: grid;
    grid-template-columns: auto;
    column-gap: 20px;
    margin-bottom: 10px;
}
.userProfile_form_label{
    color: #10002E;
    font-family: 'Inter';
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 125% */
    letter-spacing: 0.4px;
    margin-bottom: 2px;
}
.userProfile_form_row_label_field{
    display: flex;
    flex-direction: column;
}

.userProfile_form_action_button{
    margin-top: 15px;
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: end;
}
.characters{
    color: rgb(148, 145, 145);
    margin-top: 7px;
    font-size:13px ;
    font-family: "Inter";
}

/* @media only screen and (max-width:600px) {
    .userProfile_form_row{
        grid-template-columns: auto;
        margin-bottom:0px
    } */