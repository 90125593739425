.filter_Icon{
    display: none;
}
.simpleFilter_input_add_container{
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}
.simpleFilter_sort_select_container{
    display: flex;
    align-items: center;
    gap: 10px;
}
.simpleFilter_sort_select .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input{
    background-color: white;
    padding: 0.5px 3px 0.5px 6px;
    font-size: 15px;
    font-family: "Inter";    
}
.simpleFilter_sort_select .MuiMenu-paper {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
  }
  .simpleFilter_sort_select.css-8jsjs0-MuiAutocomplete-root .MuiOutlinedInput-root.MuiInputBase-sizeSmall {
  padding-bottom: 2px;
  padding-left: 6px;
}
 .simpleFilter_select .MuiAutocomplete-option {
    min-height: 0px !important;
   }
.simpleFilter_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
    padding: 4.5px 8px !important;
    font-size: 15px;
    font-family: "Inter";
    width:183px;
    margin-top: -1px;
    height: 29px;
    border-radius: 5px;
}
.filter_actionType_button{
    display: flex;
    gap: 10px;
    font-size: "14px";
    font-family: "Inter"!important;
}

@media only screen and (max-width:1316px){
    .filter_Icon{
        display: flex;
    }
   #vehicletype_filter_field_container{
    display: none;
   }
    .simpleFilter_input_add_container{
        max-width: 200px;
        padding: 15px;
        border-radius: 5px;
        background-color: white;
        position: absolute;
        display: flex;
        flex-direction: column;
        gap: 8px;
        align-items: start;
        box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
        z-index: 1
    }
    .filter_actionType_button{
        display: flex;
        margin: 0px 23px 0px auto;
        gap: 8px;
        font-family: "Inter";
        font-size: 14px;
    }
    .simpleFilter_input .css-1n4twyu-MuiInputBase-input-MuiOutlinedInput-input{
        padding: 4.5px 8px !important;
        font-size: 16px;
        font-family: "Inter";     
  
    }
    
}
